import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ReactNotifications} from "react-notifications-component"
import moment from "moment/moment";
import AppRouter, {RouteNames} from "migration/pages";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {TXT} from "../../txtMap/embedded/txt";
import {LangKey} from "../../hooks/globalContext";
import KamiService from "../../api/kamiService";
import {GlobalContextProvider} from "../../components/context/global";
import 'animate.css';
import "react-notifications-component/dist/theme.css"
import "../../App.css";
import "../../index.css";
import {notification} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {SystemActionCreators} from "./store/reducers/system/action-creators";

export const Context = React.createContext({ name: 'Default' });

export const App: FunctionComponent = () => {
    const dispatch = useDispatch();
    const [apiNotification, contextHolder] = notification.useNotification({
        placement: 'bottomRight',
        stack: true,
    });

    const [lang, setLang] = useState<LangKey>("RU")
    const {fetchCurrentUser} = useActions();
    useEffect(() => {
        moment.locale(lang)
    }, [lang])

    useEffect(() => {
        dispatch(SystemActionCreators.setNotificationAPI(apiNotification, contextHolder));
    }, [apiNotification, contextHolder, dispatch]);

    let navigate = useNavigate()

    const i18n = new TXT(lang)

    const api = new KamiService(navigate, lang, i18n)

    useEffect(() => {
        fetchCurrentUser({navigate: navigate, to: RouteNames.LOGIN});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);
    return (
        <GlobalContextProvider
            lang={lang}
            setLang={setLang}
            api={api}
            i18n={i18n}
        >
            <Context.Provider value={contextValue}>
                    {contextHolder}
            </Context.Provider>
            <ReactNotifications className={"notifications"}/>
                <AppRouter/>
        </GlobalContextProvider>
    )
}
