import {LangActionCreators} from "./lang/action-creators";
import {UserActionCreators} from "./user/action-creators";
import {AuthActionCreators} from "migration/pages/public/Login/store/auth/action-creators";
import {IikoActionCreators} from "migration/pages/user/menu/Iiko/store/action-creators";
import {KamiItemActionCreators} from "migration/pages/user/menu/iikoMenuBindings/store/action-creators";
import {NotificationActionCreators} from "migration/pages/user/menu/settings/notifications/store/action-creators";
import {FeedbackActionCreators} from "migration/pages/user/feedback/feedbackEdit/store/action-creators";
import {FeedbacksListActionCreators} from "migration/pages/user/feedback/main/store/action-creators";
import {FeedbackDashboardDataActionCreators} from "migration/pages/user/feedback/feedbackPage/store/action-creators";
import {GeneralNotificationActionCreators} from "migration/pages/user/general/Notifications/store/action-creators";
import {SystemActionCreators} from "./system/action-creators";
import {MenuActionCreators} from "migration/pages/user/menu/menu/store/action-creators";
import {CocaColaActionCreators} from "migration/pages/user/menu/cola/store/action-creators";
import {OrganizationsActionCreators} from "migration/pages/staff/organizations/store/action-creators";
import {CrossSellingActionCreators} from "migration/pages/user/crossSelling/store/action-creators";
import {OrgSettingsActionCreators} from "migration/pages/user/menu/orgSettings/store/action-creators";
import {DashboardsActionCreators} from "migration/pages/staff/dashboards/store/action-creators";
import {RkeeperActionCreators} from "migration/pages/user/menu/rkeeper/store/action-creators";

export const allActionCreators = {
    ...SystemActionCreators,
    ...LangActionCreators,
    ...UserActionCreators,
    ...AuthActionCreators,
    ...IikoActionCreators,
    ...KamiItemActionCreators,
    ...NotificationActionCreators,
    ...FeedbackActionCreators,
    ...FeedbacksListActionCreators,
    ...FeedbackDashboardDataActionCreators,
    ...GeneralNotificationActionCreators,
    ...MenuActionCreators,
    ...CocaColaActionCreators,
    ...OrganizationsActionCreators,
    ...CrossSellingActionCreators,
    ...OrgSettingsActionCreators,
    ...DashboardsActionCreators,
    ...RkeeperActionCreators,
}
