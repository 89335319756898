import React, {FunctionComponent, useEffect, useMemo, useState, CSSProperties} from "react";
import {isEmpty} from "lodash";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Collapse, Form, Input, Select} from "antd";
import {RouteNames} from "migration/pages";
import {GradeSettings, Reason, TYLink} from "migration/entities/Feedback";
import {LangsList, MlString} from "migration/entities/base/mlString";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Layout} from "migration/shared/ui/Layout";
import {Notify} from "migration/shared/lib/notification/notification";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {options} from "../../../../../utils/options/options";
import {TextI18n} from "../../../../../components/input/textI18n";
import {TextAreaI18n} from "../../../../../components/input/textAreaI18n";
import {FormFeedbackMark} from "./components/FormFeedbackMark";
import classes from "./FeedbackEdit.module.scss";
import {useSelector} from "react-redux";
import {SystemState} from "../../../../app/store/reducers/system/types";

interface ShortFeedbackPageInfo {
    name: string,
    slug: string,
    backLink: string
}

export const FeedbackEdit: FunctionComponent = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {feedbackPage} = useTypedSelector(state => state.feedback);
    const {
        getFeedbackPageById,
        renameFeedbackPage,
        changeFeedbackPageSlug,
        chooseLangsForFeedbackPage,
        setDefaultBackLinkFeedbackPage,
        saveTextsForFeedbackPage,
        setFeedbackPage,
    } = useActions();

    const notificationApi = useTypedSelector(state => state.system.notificationApi);


    const forbiddenUI: CSSProperties = {
        opacity: feedbackPage?.defaultLang ? "1" : "0.5",
        pointerEvents: feedbackPage?.defaultLang ? "auto" : "none",
    }

    // ================================================= MAIN INFO =====================================================
    const [candidateFeedbackPageMainInfo, setCandidateFeedbackPageMainInfo] = useState<ShortFeedbackPageInfo>({
        name: "",
        slug: "",
        backLink: ""
    });

    const handleChangeFeedbackMainInfoName = (name: string) => {
        setCandidateFeedbackPageMainInfo({...candidateFeedbackPageMainInfo, name: name});
    }

    const saveFeedbackPageName = () => {
        if (!candidateFeedbackPageMainInfo.name) {
            Notify.Info({title: txt.please_enter_name[currentLang], message: ""});
            return;
        }

        renameFeedbackPage({
            feedbackPageId: id || "",
            name: candidateFeedbackPageMainInfo.name
        }, {...feedbackPage, name: candidateFeedbackPageMainInfo.name}, {navigate: navigate});
    }

    const handleChangeFeedbackMainInfoSlug = (slug: string) => {
        setCandidateFeedbackPageMainInfo({...candidateFeedbackPageMainInfo, slug: slug});
    }

    const saveFeedbackPageSlug = () => {
        if (!candidateFeedbackPageMainInfo.slug) {
            Notify.Info({title: txt.please_enter_slug[currentLang], message: ""});
            return;
        }

        changeFeedbackPageSlug({
            feedbackPageId: id || "",
            slug: candidateFeedbackPageMainInfo.slug
        }, {...feedbackPage, slug: candidateFeedbackPageMainInfo.slug}, {navigate: navigate});
    }

    const handleChangeFeedbackMainInfoBackLink = (defaultBackLink: string) => {
        setCandidateFeedbackPageMainInfo({...candidateFeedbackPageMainInfo, backLink: defaultBackLink});
    }

    const saveFeedbackPageDefaultBackLink = () => {
        setDefaultBackLinkFeedbackPage({
            feedbackPageId: id || "",
            backLink: candidateFeedbackPageMainInfo.backLink
        }, {...feedbackPage, backLink: candidateFeedbackPageMainInfo.backLink}, {navigate: navigate});
    }

    // ================================================ ADDITIONAL INFO ================================================
    const [candidateFeedbackPageAdditionalInfo, setCandidateFeedbackPageAdditionalInfo] = useState<{
        defaultLang: LangsList,
        otherLangs: LangsList[]
    }>({defaultLang: LangsList.RU, otherLangs: []});

    const isFeedbackAdditionalInfoChanged = useMemo(() => {
        return (
            candidateFeedbackPageAdditionalInfo?.defaultLang !== feedbackPage?.defaultLang ||
            JSON.stringify(candidateFeedbackPageAdditionalInfo?.otherLangs || []) !== JSON.stringify(feedbackPage?.otherLangs || [])
        )
    }, [candidateFeedbackPageAdditionalInfo?.defaultLang, candidateFeedbackPageAdditionalInfo?.otherLangs, feedbackPage?.defaultLang, feedbackPage?.otherLangs]);

    const handleChangeFeedbackAdditionalInfoDefaultLang = (defaultLang: LangsList) => {
        setCandidateFeedbackPageAdditionalInfo({...candidateFeedbackPageAdditionalInfo, defaultLang: defaultLang});
    }

    const handleChangeFeedbackAdditionalInfoOtherLangs = (otherLangs: LangsList[]) => {
        setCandidateFeedbackPageAdditionalInfo({...candidateFeedbackPageAdditionalInfo, otherLangs: otherLangs});
    }

    const saveFeedbackAdditionalInfo = () => {
        if (!candidateFeedbackPageAdditionalInfo?.defaultLang) {
            Notify.Info({title: txt.please_enter_default_lang[currentLang], message: ""});
            return;
        }
        chooseLangsForFeedbackPage({
            feedbackPageId: id || "",
            defaultLang: candidateFeedbackPageAdditionalInfo?.defaultLang,
            otherLangs: candidateFeedbackPageAdditionalInfo?.otherLangs
        }, {
            ...feedbackPage,
            defaultLang: candidateFeedbackPageAdditionalInfo?.defaultLang,
            otherLangs: candidateFeedbackPageAdditionalInfo?.otherLangs
        }, {navigate: navigate});
    }

    // ===================================================== CONTENT ===================================================
    const [candidateFeedbackPageContent, setCandidateFeedbackPageContent] = useState<{
        title: MlString,
        subTitle: MlString
    }>({
        title: {} as MlString,
        subTitle: {} as MlString
    });

    const isFeedbackContentChanged = useMemo(() => {
        return (
            JSON.stringify(candidateFeedbackPageContent?.title) !== JSON.stringify(feedbackPage?.text?.title) ||
            JSON.stringify(candidateFeedbackPageContent?.subTitle) !== JSON.stringify(feedbackPage?.text?.subtitle)
        )
    }, [candidateFeedbackPageContent?.title, candidateFeedbackPageContent?.subTitle, feedbackPage?.text?.title, feedbackPage?.text?.subtitle]);

    const handleChangeFeedbackContentTitle = (title: MlString) => {
        setCandidateFeedbackPageContent({...candidateFeedbackPageContent, title: title});
    }

    const handleChangeFeedbackContentSubTitle = (subTitle: MlString) => {
        setCandidateFeedbackPageContent({...candidateFeedbackPageContent, subTitle: subTitle});
    }

    const saveFeedbackContent = () => {
        if (isEmpty(candidateFeedbackPageContent?.title)) {
            Notify.Info({title: txt.please_enter_title[currentLang], message: ""});
            return;
        }
        if (isEmpty(candidateFeedbackPageContent?.subTitle)) {
            Notify.Info({title: txt.please_enter_sub_title[currentLang], message: ""});
            return;
        }

        saveTextsForFeedbackPage({
            feedbackPageId: id || "",
            title: candidateFeedbackPageContent?.title,
            subtitle: candidateFeedbackPageContent?.subTitle
        }, {
            ...feedbackPage,
            text: {
                title: candidateFeedbackPageContent?.title,
                subtitle: candidateFeedbackPageContent?.subTitle
            }
        }, {navigate: navigate});
    }

    // ================================================= SELECT GRADE ==================================================
    const [selectedGrade, setSelectedGrade] = useState<number>(0);
    const [addedGrades, setAddedGrades] = useState<number[]>([]);

    const addGrade = () => {
        if (addedGrades.includes(selectedGrade)) {
            Notify.Info({title: txt.grade_already_added[currentLang], message: ""});
            return;
        }
        setAddedGrades([...addedGrades, selectedGrade]);
        setSelectedGrade(0);
        setFeedbackPage({
            ...feedbackPage,
            specificGrading: {
                ...feedbackPage.specificGrading, [selectedGrade]: {
                    TYText: {
                        TYTitle: {} as MlString,
                        TYSubtitle: {} as MlString,
                    },
                    TYLink: [] as TYLink[],
                    reasons: [] as Reason[],
                } as GradeSettings
            }
        })
    }

    const removeGrade = (grade: number) => {
        const feedbackPageCopy = {...feedbackPage};
        delete feedbackPageCopy.specificGrading[grade];
        setFeedbackPage(feedbackPageCopy);
        setAddedGrades(addedGrades.filter(item => item !== grade));
    }

    const feedbackMarkTitle = (grade: number) => {
        switch (grade) {
            case 1:
                return txt.on_one_star[currentLang];
            case 2:
                return txt.on_two_stars[currentLang];
            case 3:
                return txt.on_three_stars[currentLang];
            case 4:
                return txt.on_four_stars[currentLang];
            case 5:
                return txt.on_five_stars[currentLang];
            default:
                return "";
        }
    }

    // =================================================== EFFECTS =====================================================

    useEffect(() => {
        if (!id) return;
        const controller = new AbortController();
        getFeedbackPageById(id, {navigate: navigate}, controller);
        return () => controller.abort();
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (!id) return;
        if (isEmpty(feedbackPage)) return;

        setCandidateFeedbackPageMainInfo({
            name: feedbackPage?.name || "",
            slug: feedbackPage?.slug || "",
            backLink: feedbackPage?.backLink || ""
        });

        setCandidateFeedbackPageAdditionalInfo({
            defaultLang: feedbackPage?.defaultLang || LangsList.RU,
            otherLangs: feedbackPage?.otherLangs || []
        });

        setCandidateFeedbackPageContent({
            title: feedbackPage?.text?.title || {} as MlString,
            subTitle: feedbackPage?.text?.subtitle || {} as MlString
        });

        setAddedGrades(Object.keys(feedbackPage?.specificGrading || {}).map(key => Number(key)) || []);
    }, [id, feedbackPage]);

    return (
        <Layout
            title={`${txt.page_edit[currentLang]} `}
            breadcrumbs={[
                {
                    title: txt.feedbaker[currentLang],
                    link: RouteNames.FEEDBACK,
                    index: "bracer-0"
                },
                {
                    title: txt.feedback_page_edit[currentLang],
                    link: RouteNames.FEEDBACK_BY_ID_EDIT.replace(":id", id || ""),
                    index: "bracer-1"
                },
            ]}
        >
            <div className={classes.card}>
                <h2>{txt.main_info[currentLang]}</h2>
                <div className={classes.row}>
                    <Form layout={"vertical"} className={classes.form__column}>
                        <Form.Item
                            style={{marginBottom: "0"}}
                            label={<p style={{color: "#8392A5", marginBottom: "0"}}>{txt.name[currentLang]}</p>}
                            required={true}
                        >
                            <Input
                                value={candidateFeedbackPageMainInfo.name}
                                onChange={e => handleChangeFeedbackMainInfoName(e.target.value)}
                                placeholder={txt.enter_name[currentLang]}
                                onBlur={saveFeedbackPageName}
                            />
                        </Form.Item>

                        <Form.Item
                            style={{marginBottom: "0"}}
                            label={<p
                                style={{color: "#8392A5", marginBottom: "0"}}>{txt.identificator[currentLang]}</p>}
                            required={true}
                        >
                            <Input
                                value={candidateFeedbackPageMainInfo.slug}
                                onChange={e => handleChangeFeedbackMainInfoSlug(e.target.value)}
                                placeholder={txt.enter_slug[currentLang]}
                                onBlur={saveFeedbackPageSlug}
                            />
                        </Form.Item>

                        <Form.Item
                            style={{marginBottom: "0"}}
                            label={<p style={{color: "#8392A5", marginBottom: "0"}}>{txt.backlink[currentLang]}</p>}
                        >
                            <Input
                                value={candidateFeedbackPageMainInfo.backLink}
                                onChange={e => handleChangeFeedbackMainInfoBackLink(e.target.value)}
                                placeholder={txt.enter_backlink[currentLang]}
                                onBlur={saveFeedbackPageDefaultBackLink}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </div>

            <div className={classes.card}>
                <h2>{txt.additional_settings[currentLang]}</h2>
                <div className={classes.row}>
                    <Form layout={"vertical"} className={classes.form__column}>
                        <Form.Item
                            style={{marginBottom: "0"}}
                            label={
                                <p style={{color: "#8392A5", marginBottom: "0"}}>{txt.default_language[currentLang]}</p>
                            }
                            required={true}
                        >
                            <Select
                                value={candidateFeedbackPageAdditionalInfo.defaultLang}
                                onChange={handleChangeFeedbackAdditionalInfoDefaultLang}
                                options={options}
                                placeholder={txt.default_language[currentLang]}
                            />
                        </Form.Item>

                        <Form.Item
                            style={{marginBottom: "0"}}
                            label={
                                <p style={{color: "#8392A5", marginBottom: "0"}}>{txt.additional_langs[currentLang]}</p>
                            }
                        >
                            <Select
                                mode={"tags"}
                                value={candidateFeedbackPageAdditionalInfo.otherLangs}
                                onChange={handleChangeFeedbackAdditionalInfoOtherLangs}
                                options={options.filter(item => item.value !== candidateFeedbackPageAdditionalInfo.defaultLang)}
                                placeholder={txt.additional_langs[currentLang]}
                            />
                        </Form.Item>

                        <Form.Item style={{marginBottom: "0"}}>
                            <Button
                                type={"primary"}
                                onClick={saveFeedbackAdditionalInfo}
                                disabled={!isFeedbackAdditionalInfoChanged}
                            >
                                {txt.save[currentLang]}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

            <div className={classes.card}>
                <h2>{txt.content[currentLang]}</h2>

                {!feedbackPage?.defaultLang && (
                    <div className={"alert alert-info"}>
                        {txt.default_lang_not_set[currentLang]}
                    </div>
                )}

                <div
                    className={classes.row}
                    style={forbiddenUI}
                >
                    <Form layout={"vertical"} className={classes.form__column}>
                        <TextI18n
                            values={candidateFeedbackPageContent.title}
                            lang={feedbackPage?.defaultLang || LangsList.RU}
                            allLang={feedbackPage?.otherLangs || []}
                            setValues={handleChangeFeedbackContentTitle as (c: { [key: string]: string }) => void}
                            isStaff={true}
                            placeholder={txt.enter_title[currentLang]}
                            label={txt.title[currentLang]}
                            isRequired={true}
                        />

                        <TextAreaI18n
                            values={candidateFeedbackPageContent.subTitle}
                            lang={feedbackPage?.defaultLang || LangsList.RU}
                            allLangs={feedbackPage?.otherLangs || []}
                            setValues={handleChangeFeedbackContentSubTitle as (c: { [key: string]: string }) => void}
                            isStaff={true}
                            label={txt.sub_title[currentLang]}
                            isRequired={true}
                        />

                        <Form.Item style={{marginBottom: "0"}}>
                            <Button
                                type={"primary"}
                                onClick={saveFeedbackContent}
                                disabled={!isFeedbackContentChanged}
                            >
                                {txt.save[currentLang]}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

            <div className={classes.card}>
                <h2>{txt.feedbacks_settings[currentLang]}</h2>

                {!feedbackPage?.defaultLang && (
                    <div className={"alert alert-info"}>
                        {txt.default_lang_not_set[currentLang]}
                    </div>
                )}

                <Collapse
                    style={{marginBottom: "10px"}}
                    collapsible={!feedbackPage?.defaultLang ? "disabled" : undefined}
                    items={[
                        {
                            label: (
                                <div className={classes.collapse__label}>
                                    {`${txt.good_feedback[currentLang]} [4-5]`}
                                    {
                                        (
                                            isEmpty(feedbackPage?.positiveGrading?.TYText?.TYTitle) ||
                                            isEmpty(feedbackPage?.positiveGrading?.TYText?.TYSubtitle)
                                        ) && (
                                            <div className={classes.collapse__label__alert}>
                                                {txt.not_filled[currentLang]}
                                            </div>
                                        )
                                    }
                                </div>
                            ),
                            children: (
                                <React.Fragment>
                                    <h3>{`${txt.good_feedback[currentLang]} [4-5]`}</h3>
                                    <FormFeedbackMark gradeType={"positiveGrading"}/>
                                </React.Fragment>
                            )
                        }
                    ]}
                />

                <Collapse
                    style={{marginBottom: "10px"}}
                    collapsible={!feedbackPage?.defaultLang ? "disabled" : undefined}
                    items={[
                        {
                            label: (
                                <div className={classes.collapse__label}>
                                    {`${txt.negative_feedback[currentLang]} [1-3]`}
                                    {
                                        (
                                            isEmpty(feedbackPage?.negativeGrading?.TYText?.TYTitle) ||
                                            isEmpty(feedbackPage?.negativeGrading?.TYText?.TYSubtitle)
                                        ) && (
                                            <div className={classes.collapse__label__alert}>
                                                {txt.not_filled[currentLang]}
                                            </div>
                                        )
                                    }
                                </div>
                            ),
                            children: (
                                <React.Fragment>
                                    <h3>{`${txt.negative_feedback[currentLang]} [1-3]`}</h3>
                                    <FormFeedbackMark gradeType={"negativeGrading"}/>
                                </React.Fragment>
                            )
                        }
                    ]}
                />

                {Object.keys(feedbackPage?.specificGrading || {})?.map(key => {
                    const grade: 1 | 2 | 3 | 4 | 5 = Number(key) as 1 | 2 | 3 | 4 | 5;
                    return (
                        <Collapse
                            key={key}
                            style={{marginBottom: "10px"}}
                            items={[
                                {
                                    label: (
                                        <div className={classes.collapse__label}>
                                            {feedbackMarkTitle(grade)}
                                            {
                                                (
                                                    isEmpty(feedbackPage?.specificGrading?.[grade]?.TYText?.TYTitle) ||
                                                    isEmpty(feedbackPage?.specificGrading?.[grade]?.TYText?.TYSubtitle)
                                                ) && (
                                                    <div className={classes.collapse__label__alert}>
                                                        {txt.not_filled[currentLang]}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ),
                                    children: (
                                        <React.Fragment>
                                            <h3>{feedbackMarkTitle(grade)}</h3>
                                            <FormFeedbackMark gradeType={grade}/>
                                            <Button
                                                danger={true}
                                                style={{width: "100%", marginTop: "10px"}}
                                                onClick={() => removeGrade(grade)}
                                            >
                                                {txt.delete[currentLang]}
                                            </Button>
                                        </React.Fragment>
                                    )
                                }
                            ]}
                        />
                    )
                })}

                <h3 style={{marginTop: "30px"}}>{txt.add_settings_for_specific_mark[currentLang]}</h3>
                <div
                    className={classes.card__footer}
                    style={forbiddenUI}
                >
                    <Select
                        value={selectedGrade}
                        onChange={(value: number) => setSelectedGrade(value)}
                        options={[
                            {value: 0, label: txt.select_mark[currentLang]},
                            ...[1, 2, 3, 4, 5].filter(mark => !addedGrades.includes(mark)).map(item => ({
                                value: item,
                                label: item
                            }))
                        ]}
                        placeholder={txt.select_mark[currentLang]}
                        className={classes.card__select__mark}
                    />
                    <Button className={classes.card__add__mark} onClick={() => addGrade()}>
                        {txt.add[currentLang]}
                    </Button>
                </div>
            </div>

            <div className={classes.footer}>
                <Button onClick={() => navigate(RouteNames.FEEDBACK)}>
                    {txt.back[currentLang]}
                </Button>
            </div>
        </Layout>
    )
}
