import {ReactNode, useEffect, useState} from "react";
import {useGlobalContext, useUserContext} from "../globalContext";
import {useNavigate} from "react-router-dom";
import {I18nString} from "../../models/lang/i18nString";
import {
    discountFixedAmount, discountManuallyEntered, discountPercentage,
    fixedAmount,
    IDeliveryCinemaState,
    IDeliveryState,
    IInDoorsListState,
    IInDoorsState,
    IMenuOrgAttrsApi,
    IPickupState,
    percentageCharge, range, rangeCharges
} from "../../models/settings/menu";
import {Phone} from "../../models/other/phone";
import {Placement} from "../../models/placements/placements";
import {RouteNames} from "../../migration/pages";
import {isEmpty} from "lodash";
import {Notify} from "../../migration/shared/lib/notification/notification";
import {useTypedSelector} from "../../migration/shared/lib/hooks/useTypedSelector";
import {useSelector} from "react-redux";
import {NotificationInstance} from "antd/es/notification/interface";
import {RootState, store} from "../../migration/app/store";

function ParseCharge(charge: fixedAmount | percentageCharge | rangeCharges) {
    switch (charge.type) {
        case "fixedAmount":
            return {
                type: 0,
                value: charge.data.value,
                label: charge.data.name
            };
        case "percentageCharge":
            return {
                type: 1,
                value: charge.data.percents,
                label: charge.data.name
            };
        case "rangeCharge":
            return {
                type: 2,
                value: charge.data.ranges?.map(range => {
                    return {
                        from: range.from / 100,
                        to: range.to / 100,
                        value: range.value / 100,
                    }
                }),
                label: charge.data.name
            };
    }
}

function ParseDiscount(discount: discountFixedAmount | discountPercentage | discountManuallyEntered) {
    switch (discount.type) {
        case "fixedAmount":
            return {
                type: 0,
                value: discount.data.value,
                label: discount.data.name
            };
        case "percentage":
            return {
                type: 1,
                value: discount.data.percents,
                label: discount.data.name
            };
        case "manuallyEntered":
            return {
                type: 2,
                value: 0,
                label: discount.data.name
            };
    }
}

export function useSettingClientOrders() {
    const [orderCheck, setOrderCheck] = useState<boolean>(false);

    const [pickUpCheck, setPickUpCheck] = useState<boolean>(false);
    const [pickUpState, setPickUpState] = useState<IPickupState>({
        comment: {},
        paymentSupplement: [],
        discounts: [],
    });
    const [pickUpToggled, setPickUpToggled] = useState<boolean>(false);

    const [deliveryCheck, setDeliveryCheck] = useState<boolean>(false);
    const [deliveryState, setDeliveryState] = useState<IDeliveryState>({
        comment: {},
        paymentSupplement: [],
        discounts: [],
    });
    const [deliveryToggled, setDeliveryToggled] = useState<boolean>(false);

    const [inDoorCheck, setInDoorCheck] = useState<boolean>(false);
    const [inDoorState, setInDoorState] = useState<IInDoorsState>({
        comment: {},
        placeLabel: {},
        paymentSupplement: [],
        discounts: [],
    });
    const [inDoorToggled, setInDoorToggled] = useState<boolean>(false);

    const [inDoorListCheck, setInDoorListCheck] = useState<boolean>(false);
    const [inDoorListState, setInDoorListState] = useState<IInDoorsListState>({
        comment: {},
        paymentSupplement: [],
        discounts: [],
    });
    const [inDoorListToggled, setInDoorListToggled] = useState<boolean>(false);
    const [placements, setPlacements] = useState<Placement[] | null>(null);

    const [cinemaCheck, setCinemaCheck] = useState<boolean>(false);
    const [cinemaState, setCinemaState] = useState<IDeliveryCinemaState>({
        comment: {},
        paymentSupplement: [],
        discounts: [],
    });
    const [cinemaToggled, setCinemaToggled] = useState<boolean>(false);

    const [whatsAppCheck, setWhatsAppCheck] = useState<boolean>(false);
    const [whatsAppNumber, setWhatsAppNumber] = useState<Phone>({number: "", countryCode: ""});

    const {api} = useGlobalContext();

    const {currentMenuId, menu, user, setUser} = useUserContext()
    const navigate = useNavigate()

    useEffect(() => {
        let menuOrders = user?.org?.availableModules?.includes("menuOrders")
        if (!menuOrders) {
            navigate(RouteNames.SETTINGS)
        }
    }, [])

    useEffect(() => {
        if (user?.org) {
            let nowMenu = user?.org.menus.all?.find((menu) => menu.menuId === currentMenuId)
            if (nowMenu) {
                setOrderCheck(nowMenu.modulesActive['menuOrders'])
            }
        }
    }, [user, currentMenuId])

    const [settingsLoading, setSettingsLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new AbortController()
        if (!currentMenuId) {
            return
        }
        api.getMenuSettingsOrderBase({controller, setLoading: setSettingsLoading}, currentMenuId).then((res) => {
            if (res.data) {
                setWhatsAppNumber(res.data.whatsappPhone || {number: "", countryCode: "kz"})
                setWhatsAppCheck(res.data.shouldRedirectToWhatsapp);
                setPickUpCheck(!!res.data.deliveryVariants.selfPickupEmpty);
                if (!!res.data.deliveryVariants.selfPickupEmpty) {
                    setPickUpToggled(res.data.deliveryVariants.selfPickupEmpty?.isOn);
                    setPickUpState({
                        comment: res.data.deliveryVariants.selfPickupEmpty.settings.comment || {},
                        paymentSupplement: res.data.deliveryVariants.selfPickupEmpty.charges.map((charge) => ParseCharge(charge)),
                        discounts: res.data.deliveryVariants.selfPickupEmpty.discounts.map(discount => ParseDiscount(discount))
                    })
                }
                setDeliveryCheck(!!res.data.deliveryVariants.city);
                if (!!res.data.deliveryVariants.city) {
                    setDeliveryToggled(res.data.deliveryVariants.city.isOn);
                    setDeliveryState({
                        comment: res.data.deliveryVariants.city.settings.comment || {},
                        paymentSupplement: res.data.deliveryVariants.city.charges.map((charge) => ParseCharge(charge)),
                        discounts: res.data.deliveryVariants.city.discounts.map(discount => ParseDiscount(discount))
                    })
                }
                setInDoorCheck(!!res.data.deliveryVariants.customPlace);
                if (!!res.data.deliveryVariants.customPlace) {
                    setInDoorToggled(res.data.deliveryVariants.customPlace.isOn);
                    setInDoorState({
                        comment: res.data.deliveryVariants.customPlace.settings.comment || {},
                        placeLabel: res.data.deliveryVariants.customPlace.settings.placeLabel || {},
                        paymentSupplement: res.data.deliveryVariants.customPlace.charges.map((charge) => ParseCharge(charge)),
                        discounts: res.data.deliveryVariants.customPlace.discounts.map(discount => ParseDiscount(discount))
                    })
                }
                setInDoorListState({
                    comment: res.data.deliveryVariants.placements?.settings.comment || {},
                    paymentSupplement: res.data.deliveryVariants.placements?.charges.map((charge) => ParseCharge(charge)) || [],
                    discounts: res.data.deliveryVariants.placements?.discounts.map(discount => ParseDiscount(discount)) || []
                });

                setCinemaCheck(!!res.data.deliveryVariants.cinema);
                if (!!res.data.deliveryVariants.cinema) {
                    setCinemaToggled(res.data.deliveryVariants.cinema.isOn);
                    setCinemaState({
                        comment: res.data.deliveryVariants.cinema?.settings.comment || {},
                        paymentSupplement: res.data.deliveryVariants.cinema?.charges.map((charge) => ParseCharge(charge)) || [],
                        discounts: res.data.deliveryVariants.cinema.discounts.map((discount) => ParseDiscount(discount)) || []
                    });
                }
                setInDoorListCheck(!!res.data.deliveryVariants.placements);
                setInDoorListToggled(res.data.deliveryVariants.placements?.isOn || false);
                setPlacements(res.data.placements)
            }
        })
        return () => controller.abort()
    }, [currentMenuId])

    function handleOrderCheck(check: boolean) {
        setOrderCheck(check);
    }

    function parseCharges(charges: { type: number, value: number | range[], label: I18nString | null }[]) {
        return charges.map((item) => {
            switch (item.type) {
                case 0:
                    return {
                        type: "fixedAmount",
                        data: {
                            name: item.label,
                            value: item.value
                        }
                    }
                case 1:
                    return {
                        type: "percentageCharge",
                        data: {
                            name: item.label,
                            percents: item.value
                        }
                    }
                case 2:
                    return {
                        type: "rangeCharge",
                        data: {
                            name: item.label,
                            ranges: (item.value as range[])?.map((range) => {
                                return {
                                    from: range.from * 100,
                                    to: range.to * 100,
                                    value: range.value * 100,
                                }
                            })
                        }
                    }
                default:
                    return [] as any
            }
        })
    }

    function parseDiscounts(discounts: { type: number, value: number | range[], label: I18nString | null }[]) {
        return discounts.map((item) => {
            switch (item.type) {
                case 0:
                    return {
                        type: "fixedAmount",
                        data: {
                            name: item.label,
                            value: item.value
                        }
                    }
                case 1:
                    return {
                        type: "percentage",
                        data: {
                            name: item.label,
                            percents: item.value
                        }
                    }
                case 2:
                    return {
                        type: "manuallyEntered",
                        data: {
                            name: item.label,
                        }
                    }
                default:
                    return [] as any
            }
        })
    }

    function handleWhatsAppCheck(check: boolean) {
        setWhatsAppCheck(check);
    }

    function handleSave() {
        let rangeChargeValidation = {
            pickUp: {label: true, range: true},
            inDoor: {label: true, range: true},
            delivery: {label: true, range: true},
            inDoorList: {label: true, range: true},
            cinema: {label: true, range: true}
        };

        pickUpState.paymentSupplement.forEach((item) => {
            if (item.type === 2) {
                if (isEmpty(item.label)) {
                    rangeChargeValidation.pickUp.label = false;
                    return;
                }

                Object.keys(item.label).forEach((key) => {
                    if (!item.label?.[key]) {
                        rangeChargeValidation.pickUp.label = false;
                        return;
                    }
                });

                (item.value as range[]).forEach((range) => {
                    if (!range.to && !range.from) {
                        rangeChargeValidation.pickUp.range = false;
                        return;
                    }
                });
            }
        })

        inDoorState.paymentSupplement.forEach((item) => {
            if (item.type === 2) {
                if (isEmpty(item.label)) {
                    rangeChargeValidation.inDoor.label = false;
                    return;
                }
                Object.keys(item.label).forEach((key) => {
                    if (!item.label?.[key]) {
                        rangeChargeValidation.inDoor.label = false;
                        return;
                    }
                });

                (item.value as range[]).forEach((range) => {
                    if (!range.to && !range.from) {
                        rangeChargeValidation.inDoor.range = false;
                        return;
                    }
                })
            }
        })

        deliveryState.paymentSupplement.forEach((item) => {
            if (item.type === 2) {
                if (isEmpty(item.label)) {
                    rangeChargeValidation.delivery.label = false;
                    return;
                }

                Object.keys(item.label).forEach((key) => {
                    if (!item.label?.[key]) {
                        rangeChargeValidation.delivery.label = false;
                        return;
                    }
                });

                (item.value as range[]).forEach((range) => {
                    if (!range.to && !range.from) {
                        rangeChargeValidation.delivery.range = false;
                        return;
                    }
                })
            }
        })

        inDoorListState.paymentSupplement.forEach((item) => {
            if (item.type === 2) {
                if (isEmpty(item.label)) {
                    rangeChargeValidation.inDoorList.label = false;
                    return;
                }

                Object.keys(item.label).forEach((key) => {
                    if (!item.label?.[key]) {
                        rangeChargeValidation.inDoorList.label = false;
                        return;
                    }
                });

                (item.value as range[]).forEach((range) => {
                    if (!range.to && !range.from) {
                        rangeChargeValidation.inDoorList.range = false;
                        return;
                    }
                })
            }
        })

        cinemaState.paymentSupplement.forEach((item) => {
            if (item.type === 2) {
                if (isEmpty(item.label)) {
                    rangeChargeValidation.cinema.label = false;
                    return;
                }

                Object.keys(item.label).forEach((key) => {
                    if (!item.label?.[key]) {
                        rangeChargeValidation.cinema.label = false;
                        return;
                    }
                });

                (item.value as range[]).forEach((range) => {
                    if (!range.to && !range.from) {
                        rangeChargeValidation.cinema.range = false;
                        return;
                    }
                })
            }
        })

            if (!rangeChargeValidation.pickUp.label) {
                Notify.Info({title: "Cамовывоз", message: "Заполните название диапазона оплаты"});
                return;
            } else if (!rangeChargeValidation.pickUp.range) {
                Notify.Info({title: "Cамовывоз", message: "Заполните диапазон оплаты"});
                return;
            } else if (!rangeChargeValidation.inDoor.label) {
                Notify.Info({title: "Внутри помещения", message: "Заполните название диапазона оплаты"});
                return;
            } else if (!rangeChargeValidation.inDoor.range) {
                Notify.Info({title: "Внутри помещения", message: "Заполните диапазон оплаты"});
                return;
            } else if (!rangeChargeValidation.delivery.label) {
                Notify.Info({title: "Доставка", message: "Заполните название диапазона оплаты"});
                return;
            } else if (!rangeChargeValidation.delivery.range) {
                Notify.Info({title: "Доставка", message: "Заполните диапазон оплаты"});
                return;
            } else if (!rangeChargeValidation.inDoorList.label) {
                Notify.Info({title: "Внутри помещения (список)", message: "Заполните название диапазона оплаты"});
                return;
            } else if (!rangeChargeValidation.inDoorList.range) {
                Notify.Info({title: "Внутри помещения (список)", message: "Заполните диапазон оплаты"});
                return;
            } else if (!rangeChargeValidation.cinema.label) {
                Notify.Info({title: "Кинотеатр", message: "Заполните название диапазона оплаты"});
                return;
            } else if (!rangeChargeValidation.cinema.range) {
                Notify.Info({title: "Кинотеатр", message: "Заполните диапазон оплаты"});
                return;
            }


        let data: IMenuOrgAttrsApi = {
            orgId: user?.org?.id as string,
            menuId: currentMenuId,
            isOn: orderCheck,
            shouldRedirectToWhatsapp: whatsAppCheck,
            whatsappPhone: whatsAppCheck ? whatsAppNumber : null,
            deliveryVariants: {
                selfPickupEmpty: pickUpCheck ? {
                    "isOn": pickUpToggled,
                    settings: {
                        comment: pickUpState.comment,
                    },
                    "charges": parseCharges(pickUpState.paymentSupplement),
                    "discounts": parseDiscounts(pickUpState.discounts)
                } : null,
                customPlace: inDoorCheck ? {
                    "isOn": inDoorToggled,
                    settings: {
                        comment: inDoorState.comment,
                        placeLabel: inDoorState.placeLabel
                    },
                    "charges": parseCharges(inDoorState.paymentSupplement),
                    "discounts": parseDiscounts(inDoorState.discounts)
                } : null,
                city: deliveryCheck ? {
                    "isOn": deliveryToggled,
                    settings: {
                        comment: deliveryState.comment,
                    },
                    "charges": parseCharges(deliveryState.paymentSupplement),
                    "discounts": parseDiscounts(deliveryState.discounts)
                } : null,
                placements: placements && placements.length > 0 && inDoorListCheck ? {
                    "isOn": inDoorListToggled,
                    settings: {
                        comment: inDoorListState.comment,
                    },
                    charges: parseCharges(inDoorListState.paymentSupplement),
                    "discounts": parseDiscounts(inDoorListState.discounts)
                } : null,
                cinema: cinemaCheck ? {
                    "isOn": cinemaToggled,
                    settings: {
                        comment: cinemaState.comment,
                    },
                    "charges": parseCharges(cinemaState.paymentSupplement),
                    "discounts": parseDiscounts(cinemaState.discounts)
                } : null,
            }
        }
        api.updateMenuSettingsOrderBase({setLoading: setSaveLoading}, data).then((res) => {
            if (res.success) {
                if (user?.org) {
                    let list = user?.org.menus.all
                    if (list) {
                        list.map((item) => {
                            if (item.menuId === currentMenuId) {
                                item.modulesActive['menuOrders'] = orderCheck
                            }
                            return item
                        })
                    }
                    setUser({...user, org: {...user?.org, menus: {...user?.org.menus, all: list}}})
                }
            }
        })
    }

    return {
        orderCheck,
        menu,

        inDoorState,
        setInDoorState,
        inDoorCheck,
        setInDoorCheck,
        inDoorToggled,
        setInDoorToggled,
        handleSave,

        placements,
        inDoorListState,
        setInDoorListState,
        inDoorListCheck,
        setInDoorListCheck,
        inDoorListToggled,
        setInDoorListToggled,


        deliveryState,
        setDeliveryState,
        deliveryCheck,
        setDeliveryCheck,
        deliveryToggled,
        setDeliveryToggled,

        pickUpState,
        setPickUpState,
        pickUpCheck,
        setPickUpCheck,
        pickUpToggled,
        setPickUpToggled,

        cinemaState,
        setCinemaState,
        cinemaCheck,
        setCinemaCheck,
        cinemaToggled,
        setCinemaToggled,

        handleOrderCheck,

        whatsAppCheck,
        handleWhatsAppCheck,
        whatsAppNumber,
        setWhatsAppNumber,

        saveLoading,
        settingsLoading
    }
}
