import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CloseCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {v4 as uuidv4} from "uuid";
import {isEmpty} from "lodash";
import {Modal, Select} from "antd";
import {RouteNames} from "migration/pages";
import {txt} from "migration/shared/lib/core/i18ngen";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {Breadcrumbs} from "../../../../../../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext} from "../../../../../../../hooks/globalContext";
import classes from "./Mentions.module.scss";
import {Notify} from "../../../../../../shared/lib/notification/notification";
import {LangsList} from "../../../../../../entities/base/mlString";

export const Mentions: FunctionComponent = () => {
    const navigate = useNavigate();
    const {userInfoLoading} = useGlobalContext();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {currentMenuId, menu} = useTypedSelector(state => state.user);
    const {menuData, kamiMenuItemsList, kamiMenuCategoryList} = useTypedSelector(state => state.menuIiko);
    const {mentionsMapping} = useTypedSelector(state => state.menu);

    const {
        fetchMenuData,
        addProductMentionToProduct,
        productMentionsOfMenu,
        removeProductMention,
        addProductMentionToTopOfCategory
    } = useActions();

    const [isAddModalOpenToProduct, setIsAddModalOpenToProduct] = useState<boolean>(false);
    const [isAddModalOpenToCategory, setIsAddModalOpenToCategory] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [selectedToProductId, setSelectedToProductId] = useState<string>("");
    const [selectedToCategoryId, setSelectedToCategoryId] = useState<string>("");
    const [selectedProductId, setSelectedProductId] = useState<string>("");
    const [selectedMentionProduct, setSelectedMentionProduct] = useState<string>("");

    const handleAddMentionToProduct = () => {
        if (!selectedToProductId || !selectedProductId) {
            Notify.Info({title: txt.select_product[currentLang], message: ""});
            return;
        }
        addProductMentionToProduct(
            {
                "menuId": currentMenuId,
                "mentionId": uuidv4(),
                "productId": selectedProductId,
                "toProductId": selectedToProductId,
            },
            {
                navigate: navigate
            }
        );
        setIsAddModalOpenToProduct(false);
        setSelectedProductId("");
        setSelectedToProductId("");
    }

    const handleAddMentionToCategory = () => {
        if (!selectedToCategoryId || !selectedProductId) {
            Notify.Info({title: txt.select_product[currentLang], message: ""});
            return;
        }
        addProductMentionToTopOfCategory(
            {
                "menuId": currentMenuId,
                "mentionId": uuidv4(),
                "productId": selectedProductId,
                "toCategoryId": selectedToCategoryId,
            },
            {
                navigate: navigate
            }
        );
        setIsAddModalOpenToCategory(false);
        setSelectedProductId("");
        setSelectedToCategoryId("");
    }

    const handleCloseAddModal = () => {
        setIsAddModalOpenToProduct(false);
        setIsAddModalOpenToCategory(false);
        setSelectedProductId("");
        setSelectedToProductId("");
        setSelectedToCategoryId("");
    }

    const handleRemoveMentionProduct = (toProductId: string, toCategoryId: string) => {
        if (!selectedMentionProduct) {
            Notify.Info({title: txt.select_product[currentLang], message: ""});
            return;
        }
        removeProductMention(
            {
                "menuId": currentMenuId,
                "mentionId": selectedMentionProduct,
            },
            {
                toProductId: toProductId,
                toCategoryId: toCategoryId
            },
            {navigate: navigate}
        );
        setIsEditModalOpen(false);
        setSelectedProductId("");
        setSelectedToProductId("");
        setSelectedToCategoryId("");
    }

    const handleCloseRemoveModal = () => {
        setIsEditModalOpen(false);
        setSelectedProductId("");
        setSelectedToProductId("");
        setSelectedToCategoryId("");
        setSelectedMentionProduct("");
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchMenuData({menuId: currentMenuId}, controller, {navigate: navigate});
        return () => controller.abort();
        // eslint-disable-next-line
    }, [currentMenuId]);

    useEffect(() => {
        const controller = new AbortController();
        productMentionsOfMenu({menuId: currentMenuId}, controller, {navigate: navigate});
        return () => controller.abort();
        // eslint-disable-next-line
    }, [currentMenuId]);

    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">
                <Breadcrumbs infos={[
                    {title: txt.menus[currentLang], link: RouteNames.MENUS, index: "brcr-0"},
                    {title: txt.mention_product[currentLang], link: RouteNames.MENUS_MENTIONS, index: "brcr-1"},
                ]}/>

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{txt.mention_product[currentLang]}</h1>
                    </div>
                </div>

                <div className={classes.tree}>
                    {menuData?.sections?.map(section => (
                        <div className={classes.tree__section__container} key={section.section.id}>
                            <div className={classes.tree__section}>
                                <div className={classes.tree__section__name}>
                                    {section.section.name[menu?.i18n?.defaultLang || LangsList.RU]}
                                </div>

                                <div className={classes.tree__icon__group}>

                                </div>
                            </div>

                            {section.categories?.map(category => (
                                <div className={classes.tree__category__container} key={category.category.id}>
                                    <div className={classes.tree__category}>
                                        <div className={classes.tree__category__name}>
                                            {category.category.name[menu?.i18n?.defaultLang || LangsList.RU]}
                                        </div>

                                        <div className={classes.tree__icon__group}>
                                            <PlusCircleOutlined
                                                onClick={() => {
                                                    setSelectedToCategoryId(category.category.id);
                                                    setIsAddModalOpenToCategory(true);
                                                }}
                                                className={classes.tree__icon__group__item}
                                            />
                                        </div>
                                    </div>

                                    {!isEmpty(mentionsMapping?.toCategories?.[category.category.id]) && (
                                        mentionsMapping?.toCategories?.[category.category.id]?.map(mention => {
                                            if (isEmpty(kamiMenuItemsList[mention.productId])) return null;
                                            return (
                                                <div
                                                    key={mention.mentionId}
                                                    className={classes.tree__mention__container}
                                                    style={{width: "calc(100% - 90px)", marginLeft: "90px"}}
                                                >
                                                    <div className={classes.tree__mention}>
                                                        <div className={classes.tree__mention__name}>
                                                            {kamiMenuItemsList[mention.productId]?.name?.[menu?.i18n?.defaultLang || LangsList.RU]}
                                                        </div>

                                                        <div className={classes.tree__icon__group}>
                                                            <CloseCircleOutlined
                                                                onClick={() => {
                                                                    setSelectedToCategoryId(category.category.id);
                                                                    setSelectedProductId(mention.productId)
                                                                    setSelectedMentionProduct(mention.mentionId);
                                                                    setIsEditModalOpen(true);
                                                                }}
                                                                className={classes.tree__icon__group__item}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )}

                                    {category.items?.map(item => (
                                        <div className={classes.tree__item__container} key={item.id}>
                                            <div className={classes.tree__item}>
                                                <div className={classes.tree__item__name}>
                                                    {item.name[menu?.i18n?.defaultLang || LangsList.RU]}
                                                </div>

                                                <div className={classes.tree__icon__group}>
                                                    <PlusCircleOutlined
                                                        onClick={() => {
                                                            setSelectedToProductId(item.id);
                                                            setIsAddModalOpenToProduct(true);
                                                        }}
                                                        className={classes.tree__icon__group__item}
                                                    />
                                                </div>
                                            </div>

                                            {!isEmpty(mentionsMapping?.toProducts?.[item.id]) && (
                                                mentionsMapping?.toProducts?.[item.id]?.map(mention => (
                                                    <div
                                                        key={mention.mentionId}
                                                        className={classes.tree__mention__container}
                                                    >
                                                        <div className={classes.tree__mention}>
                                                            <div className={classes.tree__mention__name}>
                                                                {kamiMenuItemsList[mention.productId]?.name?.[menu?.i18n?.defaultLang || LangsList.RU]}
                                                            </div>

                                                            <div className={classes.tree__icon__group}>
                                                                <CloseCircleOutlined
                                                                    onClick={() => {
                                                                        setSelectedToProductId(item.id);
                                                                        setSelectedProductId(mention.productId);
                                                                        setSelectedMentionProduct(mention.mentionId);
                                                                        setIsEditModalOpen(true);
                                                                    }}
                                                                    className={classes.tree__icon__group__item}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </main>

            <Modal
                open={isAddModalOpenToProduct}
                onOk={handleAddMentionToProduct}
                onCancel={handleCloseAddModal}
                okText={txt.add[currentLang]}
                cancelText={txt.cancel[currentLang]}
                title={txt.add_mention_to_product[currentLang]}
            >
                <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                    <label>
                        {`${txt.mention_to_this_product[currentLang]}: `}
                        <span style={{fontWeight: "600"}}>
                                    {kamiMenuItemsList?.[selectedToProductId]?.name?.[menu?.i18n?.defaultLang || LangsList.RU]}
                                </span>
                    </label>

                    <label>{txt.mentioned_product[currentLang]}</label>
                    <Select
                        value={selectedProductId}
                        onChange={value => setSelectedProductId(value)}
                        showSearch
                        options={
                            [
                                {value: "", label: txt.select_product[currentLang]},
                                ...Object.keys(kamiMenuItemsList).map(itemId => ({
                                    value: itemId,
                                    label: kamiMenuItemsList[itemId].name[menu?.i18n?.defaultLang || LangsList.RU]
                                }))
                            ]
                        }
                        filterOption={
                            (input: string, option?: { label: string; value: string }) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </div>
            </Modal>

            <Modal
                open={isAddModalOpenToCategory}
                onOk={handleAddMentionToCategory}
                onCancel={handleCloseAddModal}
                okText={txt.add[currentLang]}
                cancelText={txt.cancel[currentLang]}
                title={txt.add_mention_to_category[currentLang]}
            >
                <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                    <label>
                        {`${txt.mention_to_this_category[currentLang]}: `}
                        <span style={{fontWeight: "600"}}>
                            {kamiMenuCategoryList?.[selectedToCategoryId]?.category?.name?.[menu?.i18n?.defaultLang || LangsList.RU]}
                        </span>
                    </label>

                    <label>{txt.mentioned_product[currentLang]}</label>
                    <Select
                        value={selectedProductId}
                        onChange={value => setSelectedProductId(value)}
                        showSearch
                        options={
                            [
                                {value: "", label: txt.select_product[currentLang]},
                                ...Object.keys(kamiMenuItemsList).map(itemId => ({
                                    value: itemId,
                                    label: kamiMenuItemsList[itemId].name[currentLang]
                                }))
                            ]
                        }
                        filterOption={
                            (input: string, option?: { label: string; value: string }) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </div>
            </Modal>

            <Modal
                open={isEditModalOpen}
                onOk={() => handleRemoveMentionProduct(selectedToProductId || "", selectedToCategoryId)}
                onCancel={handleCloseRemoveModal}
                okText={txt.delete[currentLang]}
                cancelText={txt.cancel[currentLang]}
                title={!!selectedToCategoryId ? txt.remove_mention_to_category[currentLang] : txt.remove_mention_to_product[currentLang]}
            >
                {
                    !!selectedToCategoryId ? (
                        <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                            <p>
                                {`${txt.are_you_sure_to_delete_this_mention[currentLang]}:`}
                            </p>
                            <p>
                        <span style={{fontWeight: "600"}}>
                            {kamiMenuItemsList?.[selectedProductId]?.name?.[menu?.i18n?.defaultLang || LangsList.RU]}
                        </span>
                                {` ${txt.from[currentLang]} `}
                                <span style={{fontWeight: "600"}}>
                            {kamiMenuCategoryList?.[selectedToCategoryId]?.category?.name?.[menu?.i18n?.defaultLang || LangsList.RU]}
                        </span>
                            </p>
                        </div>
                    ) : (
                        <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                            <p>
                                {`${txt.are_you_sure_to_delete_this_mention[currentLang]}:`}
                            </p>
                            <p>
                        <span style={{fontWeight: "600"}}>
                            {kamiMenuItemsList?.[selectedProductId]?.name?.[menu?.i18n?.defaultLang || LangsList.RU]}
                        </span>
                                {` ${txt.from[currentLang]} `}
                                <span style={{fontWeight: "600"}}>
                            {kamiMenuItemsList?.[selectedToProductId]?.name?.[menu?.i18n?.defaultLang || LangsList.RU]}
                        </span>
                            </p>
                        </div>
                    )
                }
            </Modal>
        </section>
    );
};
