import React, {FunctionComponent, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {RcFile} from "antd/es/upload";
import {Modal, Upload, UploadFile, UploadProps} from "antd";
import {FileOutlined, InboxOutlined} from "@ant-design/icons";
import {isEmpty} from "lodash";
import {RouteNames} from "migration/pages";
import {IikoStopLists} from "migration/entities/iikoStopLists";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Notify} from "migration/shared/lib/notification/notification";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {useMenu} from "../../hooks/menu/menu";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import ItemSquare from "../../resourse/img/item_square.png";
import {Image} from "../../components/image/image";
import {CURRENCY} from "../../utils/options/options";
import {LangKey, useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {ModalPreView} from "../../components/modal/modalPreview";
import {addNotification, addNotificationWithButton} from "../../utils/toast/toast";
import {categoryTree, MenuTree, treeItem} from "../../models/menu/menuTree";
import {ReactComponent as EditSVG} from '../../resourse/img/general/ic-edit.svg';
import {ReactComponent as DeleteSVG} from '../../resourse/img/general/ic-trash.svg';
import {ReactComponent as TurnOnSVG} from '../../resourse/img/general/ic-turn-on.svg';
import {ReactComponent as TurnOffSVG} from '../../resourse/img/general/ic-turn-off.svg';
import {ReactComponent as ShowSVG} from '../../resourse/img/general/ic-show.svg';
import {ReactComponent as HideSVG} from '../../resourse/img/general/ic-hide.svg';
import {ReactComponent as AddSVG} from '../../resourse/img/general/ic-plus_gray.svg';
import {ReactComponent as CopySVG} from '../../resourse/img/general/ic-copy.svg';
import {MenuSelect} from "../../components/input/menuSelect";
import {MenuUlWithLoader} from "../../components/loader/menuUlWithLoader";

export function Menu() {
    const navigate = useNavigate();
    const info = useMenu()
    const {user, currentMenuId, iikoActiveMenu} = useUserContext();
    const {i18n, userInfoLoading} = useGlobalContext()

    const {iikoStopLists, iikoMenuSettings, iikoNomenclature} = useTypedSelector(state => state.iiko);
    const {rkeeperMenuSettings, rkeeperMenu} = useTypedSelector(state => state.rkeeper);
    const {
        fetchIikoStopLists,
        fetchIikoMenuSettings,
        fetchIikoNomenclature,
        getMenuRkeeperSettings,
        getRkeeperMenu,
        fetchIikoExternalMenu
    } = useActions();

    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (iikoActiveMenu) {
            const controller = new AbortController();
            fetchIikoMenuSettings({menuId: currentMenuId}, controller, {navigate: navigate});
            return () => controller.abort();
        }
    }, [iikoActiveMenu]);

    useEffect(() => {
        if (iikoActiveMenu && !!iikoMenuSettings?.iikoOrganizationId) {
            if (iikoMenuSettings.iikoExternalMenuId) {
                const controller = new AbortController();
                fetchIikoExternalMenu(
                    {
                        organizationId: user?.org?.id || "",
                        iikoOrganizationId: iikoMenuSettings?.iikoOrganizationId,
                        iikoExternalMenuId: iikoMenuSettings?.iikoExternalMenuId,
                        iikoPriceCategoryId: iikoMenuSettings?.iikoPriceCategoryId || null
                    }, controller, {navigate: navigate}
                );
                return () => controller.abort();
            } else {
                const controller = new AbortController();
                fetchIikoNomenclature(
                    {organizationId: user?.org?.id || "", iikoOrganizationId: iikoMenuSettings?.iikoOrganizationId},
                    controller,
                    {navigate: navigate}
                );
                return () => controller.abort();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iikoMenuSettings, iikoActiveMenu])

    useEffect(() => {
        if (iikoActiveMenu) {
            const controller = new AbortController();
            fetchIikoStopLists({organizationId: user?.org?.id || ""}, controller, {navigate: navigate});
            return () => controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iikoActiveMenu]);

    useEffect(() => {
        document.title = i18n.menus()
    }, [i18n])

    useEffect(() => {
        if (user?.org?.availableModules?.includes("rkeeper")) {
            const controller = new AbortController();
            getMenuRkeeperSettings({menuId: currentMenuId}, controller);
            return () => controller.abort();
        }
    }, [currentMenuId]);

    useEffect(() => {
        if (user?.org?.availableModules?.includes("rkeeper")) {
            if (isEmpty(rkeeperMenuSettings)) return;

            const controller = new AbortController();
            getRkeeperMenu({objectId: Number(rkeeperMenuSettings.rkeeperObjectId)}, controller);
            return () => controller.abort();
        }
    }, [rkeeperMenuSettings]);

    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">

                <Breadcrumbs infos={[
                    {title: i18n.menus(), link: RouteNames.MENUS, index: "brcr-0"}
                ]}/>


                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.menus()}</h1>
                    </div>
                    <div className="app__header-right" style={{display: "flex"}}>
                        {(user?.isStaff && (info.menuInfo.sections || []).length === 0) && (
                            <div className="button -blue" onClick={() => setIsFileUploadModalOpen(true)}>
                                <FileOutlined/>
                                {i18n.upload_file()}
                            </div>
                        )}
                        {user?.isStaff && (
                            <div className="button -blue" onClick={() => navigate(RouteNames.MENUS_MENTIONS)}>
                                {i18n.mention_product()}
                            </div>
                        )}
                        {(info.menuInfo.sections || []).length
                            ?
                            <Link to={RouteNames.MENUS_SORTING} km-partial="true" className="button -blue">
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink={"http://www.w3.org/1999/xlink"} x="0px" y="0px" width="15" height="15"
                                     viewBox="0 0 384 384" enableBackground={"new 0 0 384 384"} xmlSpace="preserve">
                                    <polygon
                                        points="128,0 42.667,85.12 106.667,85.12 106.667,234.667 149.333,234.667 149.333,85.12 213.333,85.12 			"
                                        fill="white"></polygon>
                                    <polygon
                                        points="277.333,298.88 277.333,149.333 234.667,149.333 234.667,298.88 170.667,298.88 256,384 341.333,298.88 			"
                                        fill="white"></polygon>
                                </svg>
                                {i18n.sort()}
                            </Link>
                            :
                            null
                        }
                        {info.user?.org?.menus.menus
                            ? info.user?.org.menus.menus?.length > 1
                                ?
                                (
                                    <MenuSelect
                                        options={
                                            [
                                                {
                                                    id: "",
                                                    name: i18n.copy_total_menus(),
                                                    link: "",
                                                },
                                                ...(info.user?.org?.menus.menus?.filter((v) => v.id !== info.currentMenuId) || [])
                                            ]
                                        }
                                        value={""} onChange={info.copyMenu}/>
                                )
                                : null
                            : null
                        }
                        {info.iikoActiveMenu && info.iikoBindOrg &&
                            <Link to={RouteNames.MENUS_COPY_IIKO} km-partial="true"
                                  className="button -blue"
                            >
                                {i18n.copy_iiko()}
                            </Link>
                        }

                        {user?.org?.availableModules?.includes("rkeeper") &&
                            <Link to={RouteNames.MENUS_COPY_R_KEEPER} km-partial="true"
                                  className="button -blue"
                            >
                                {i18n.copy_r_keeper()}
                            </Link>
                        }

                        {info.iikoActiveMenu && info.iikoBindOrg &&
                            <Link to={RouteNames.MENUS_IIKO_PRODUCT_BINDINGS} km-partial="true"
                                  className="button -blue"
                            >
                                {i18n.iiko_product_bindings()}
                            </Link>
                        }

                        {user?.org?.availableModules?.includes("rkeeper") &&
                            <Link to={RouteNames.MENUS_R_KEEPER_PRODUCT_BINDINGS} km-partial="true"
                                  className="button -blue"
                            >
                                {i18n.rkeeper_product_bindings()}
                            </Link>
                        }

                        <Link to={RouteNames.MENUS_SECTION_CREATE} km-partial="true"
                              className="button -blue"
                        >
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.6607 6.16071H9.10714C8.95922 6.16071 8.83929 6.04078 8.83929 5.89286V1.33929C8.83929 0.59967 8.23962 0 7.5 0C6.76038 0 6.16071 0.59967 6.16071 1.33929V5.89286C6.16071 6.04078 6.04078 6.16071 5.89286 6.16071H1.33929C0.59967 6.16071 0 6.76038 0 7.5C0 8.23962 0.59967 8.83929 1.33929 8.83929H5.89286C6.04078 8.83929 6.16071 8.95922 6.16071 9.10714V13.6607C6.16071 14.4003 6.76038 15 7.5 15C8.23962 15 8.83929 14.4003 8.83929 13.6607V9.10714C8.83929 8.95922 8.95922 8.83929 9.10714 8.83929H13.6607C14.4003 8.83929 15 8.23962 15 7.5C15 6.76038 14.4003 6.16071 13.6607 6.16071Z"
                                    fill="white"></path>
                            </svg>
                            {i18n.add_section()}
                        </Link>
                    </div>
                </div>
                {/*{info.iikoBindOrg && !info.stopListLoading && !info.stopList ?*/}
                {/*    <div className="alert alert-warning py-4">*/}
                {/*        {i18n.iiko_settings_empty_message()}*/}
                {/*    </div>*/}
                {/*    : null}*/}


                <MenuUlWithLoader loading={info.menuLoading} count={2} level={3}
                                  notFound={(info.menuInfo.sections || []).length === 0 && !info.menuLoading}
                                  notFoundText={i18n.help_empty_menu()}
                                  id="catalog" className="catalog">
                    {(info.menuInfo.sections || []).map((sect) => {
                        let secHidden = sect.section.isHidden ? "hidden-item" : ""
                        return (
                            <li className={"catalog__item"}
                                key={sect.section.id}>
                                <div className={"catalog__title"}>
                                    <Link to={"#"}
                                          className={info.collapse[sect.section.id] ? `catalog__text collapsed ${secHidden}` : `catalog__text ${secHidden}`}
                                          aria-expanded={info.collapse[sect.section.id] ? "true" : "false"}
                                          onClick={() => {
                                              info.onCollapse(sect.section.id)
                                          }}>
                                        {(info.menuInfo.map || {})[sect.section.id] ?
                                            <CopySVG title={i18n.paired_element()}/> : null}
                                        {sect.section.name[info.menu.i18n.defaultLang]}

                                        <span
                                            className="catalog__subtext">({sect.categories ? sect.categories.length : 0})
                                        </span>
                                    </Link>
                                    <div
                                        className={sect.section.id === info.dropLeft ? "catalog__submenu dropleft show" : "catalog__submenu dropleft"}
                                        ref={sect.section.id === info.dropLeft ? info.ref : undefined}
                                    >
                                        <Link to="#" className="catalog__submenu-toggle"
                                              onClick={() => {
                                                  info.onDropLeft(sect.section.id)
                                              }}
                                              aria-expanded={sect.section.id === info.dropLeft ? "true" : "false"}>
                                        </Link>
                                        <div
                                            className={sect.section.id === info.dropLeft ? "dropdown-menu dropdown-menu-left show" : "dropdown-menu dropdown-menu-left dropleft"}
                                            tabIndex={0}>
                                            <Link
                                                to={RouteNames.MENUS_CATEGORY_CREATE + "/?section_id=" + sect.section.id}
                                                className="catalog__submenu-link"
                                            >
                                                <AddSVG/>
                                                {i18n.add_category()}
                                            </Link>
                                            <Link to={RouteNames.MENUS_SECTION_EDIT.replace(":id", sect.section.id)}
                                                  className="catalog__submenu-link">
                                                <EditSVG/>
                                                {i18n.edit()}
                                            </Link>
                                            <Link
                                                to={"#"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    addNotificationWithButton({
                                                        message: i18n.q_delete_section(),
                                                        onSubmit: () => {
                                                            info.onDeleteSection(sect.section.id)
                                                        },
                                                        loading: info.deleteSectionLoading,
                                                        i18n
                                                    })
                                                }}
                                                className="catalog__submenu-link">
                                                <DeleteSVG/>
                                                {i18n.delete()}
                                            </Link>

                                            {sect.section.isHidden ?
                                                <Link to="#" className="catalog__submenu-link"
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          info.onShowSection(sect.section.id)
                                                      }}
                                                >
                                                    <ShowSVG/>
                                                    {i18n.show_in_menu()}
                                                </Link> :
                                                <Link to="#" className="catalog__submenu-link"
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          info.onHideSection(sect.section.id)
                                                      }}
                                                >
                                                    <HideSVG/>
                                                    {i18n.hide_in_menu()}
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={info.collapse[sect.section.id] ? "collapse show" : "collapse"}>
                                    {(sect.categories || []).map((cat) => {
                                        return (
                                            <MenuCategory
                                                category={cat} collapse={info.collapse}
                                                dropLeft={info.dropLeft} ref={info.ref}
                                                onCollapse={info.onCollapse}
                                                sectionId={sect.section.id}
                                                onDropLeft={info.onDropLeft}
                                                setMenuInfo={info.setMenuInfo}
                                                key={cat.category.id} lang={info.menu.i18n.defaultLang}
                                                isCopy={(info.menuInfo.map || {})[cat.category.id]}
                                            >
                                                {(cat.items || []).map((item) => {
                                                    return (
                                                        <MenuItem
                                                            item={item} ref={info.ref} dropLeft={info.dropLeft}
                                                            onDropLeft={info.onDropLeft}
                                                            isCopy={(info.menuInfo.map || {})[item.id]}
                                                            setModalItem={info.setModalItem}
                                                            setMenuInfo={info.setMenuInfo} stopList={iikoStopLists}
                                                            key={item.id} lang={info.menu.i18n.defaultLang}
                                                        />
                                                    )
                                                })}
                                            </MenuCategory>
                                        )
                                    })}
                                </div>
                            </li>
                        )
                    })}
                </MenuUlWithLoader>
                <ModalPreView
                    modalInfo={info.modalItem}
                    setModalInfo={info.setModalItem}
                    menu={info.menu}
                    iikoNomenclature={iikoNomenclature}
                    iikoMenuSettings={iikoMenuSettings}
                    iikoStopLists={iikoStopLists}
                    rkeeperMenu={rkeeperMenu}
                    rkeeperMenuSettings={rkeeperMenuSettings}
                />
                <FileUploadModal open={isFileUploadModalOpen} setOpen={setIsFileUploadModalOpen}/>
            </main>
        </section>
    )
}

interface IMenuCategory {
    category: categoryTree,
    collapse: { [p: string]: boolean },
    dropLeft: string,
    sectionId: string,
    onCollapse: (id: string) => void,
    ref: React.MutableRefObject<null>,
    children: React.ReactNode,
    onDropLeft: (id: string) => void,
    setMenuInfo: React.Dispatch<React.SetStateAction<MenuTree>>
    lang: LangKey
    isCopy?: boolean
}

function MenuCategory(props: IMenuCategory) {
    const {currentMenuId} = useUserContext()
    const {api, i18n} = useGlobalContext()

    const [deleteLoading, setDeleteLoading] = useState(false)
    const [hideLoading, setHideLoading] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    //todo

    function onDeleteCategory(id: string) {
        api.deleteCategory({setLoading: setDeleteLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                // delete menu category
                props.setMenuInfo(menuInfo => {
                    return {
                        ...menuInfo,
                        sections: (menuInfo.sections || []).map(section => {
                            section.categories = (section.categories || []).filter(category => category.category.id !== id)
                            return section
                        })
                    }
                })
            }
        })
    }

    function onShowCategory(id: string) {
        api.revealCategory({setLoading: setShowLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                // show menu category
                props.setMenuInfo(menuInfo => {
                    return {
                        ...menuInfo,
                        sections: (menuInfo.sections || []).map(section => {
                            section.categories = (section.categories || []).map(category => {
                                if (category.category.id === id) {
                                    category.category.isHidden = false
                                }
                                return category
                            })
                            return section
                        })
                    }
                })
            }
        })
    }

    function onHideCategory(id: string) {
        api.hideCategory({setLoading: setHideLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                // hide menu category
                props.setMenuInfo(menuInfo => {
                    return {
                        ...menuInfo,
                        sections: (menuInfo.sections || []).map(section => {
                            section.categories = (section.categories || []).map(category => {
                                if (category.category.id === id) {
                                    category.category.isHidden = true
                                }
                                return category
                            })
                            return section
                        })
                    }
                })
            }
        })
    }


    return (
        <div
            className={"catalog__body"}
            key={props.category.category.id}>
            <div className="catalog__title">
                <Link to="#"
                      className={props.collapse[props.category.category.id] ? `catalog__text ${props.category.category.isHidden ? "hidden-item" : ""}`
                          : `catalog__text collapsed ${props.category.category.isHidden ? "hidden-item" : ""}`}
                      aria-expanded={props.collapse[props.category.category.id] ? "true" : "false"}
                      onClick={() => {
                          props.onCollapse(props.category.category.id)
                      }}
                >
                    <span className="catalog__arrow"></span>
                    {props.isCopy ? <CopySVG title={i18n.paired_element()}/> : null}
                    {props.category.category.name[props.lang]}

                    <span
                        className="catalog__subtext">({props.category.items ? props.category.items.length : 0})</span>
                </Link>
                <div
                    className={props.category.category.id === props.dropLeft ? "catalog__submenu dropleft show" : "catalog__submenu dropleft"}
                    ref={props.category.category.id === props.dropLeft ? props.ref : undefined}
                >
                    <Link to="#" className="catalog__submenu-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded={props.dropLeft === props.category.category.id}
                          onClick={() => {
                              props.onDropLeft(props.category.category.id)
                          }}
                    >
                    </Link>
                    <div
                        className={props.dropLeft === props.category.category.id ? "dropdown-menu dropdown-menu-left show" : "dropdown-menu dropdown-menu-left"}
                        aria-labelledby="ddl-f6c7d826-b671-4f18-aad2-327bbbd2a858"
                        tabIndex={0}>
                        <Link
                            to={RouteNames.MENUS_ITEM_CREATE + `/?section_id=${props.sectionId}&category_id=${props.category.category.id}`}
                            className="catalog__submenu-link"
                        >
                            <AddSVG/>
                            {i18n.add_item()}
                        </Link>
                        <Link
                            to={RouteNames.MENUS_CATEGORY_EDIT.replace(":id", props.category.category.id)}
                            className="catalog__submenu-link"
                        >
                            <EditSVG/>
                            {i18n.edit()}
                        </Link>
                        <Link
                            to={"#"}
                            onClick={(e) => {
                                e.preventDefault()
                                addNotificationWithButton({
                                    message: i18n.q_delete_category(),
                                    onSubmit: () => onDeleteCategory(props.category.category.id),
                                    loading: deleteLoading,
                                    i18n
                                })
                            }}
                            className="catalog__submenu-link">
                            <DeleteSVG/>
                            {i18n.delete()}
                        </Link>
                        {props.category.category.isHidden ?
                            <Link to="#" className="catalog__submenu-link"
                                  onClick={(e) => {
                                      e.preventDefault()
                                      onShowCategory(props.category.category.id)
                                  }}
                            >
                                <ShowSVG/>
                                {i18n.show_in_menu()}
                            </Link> :
                            <Link to="#" className="catalog__submenu-link"
                                  onClick={(e) => {
                                      e.preventDefault()
                                      onHideCategory(props.category.category.id)
                                  }}
                            >
                                <HideSVG/>
                                {i18n.hide_in_menu()}
                            </Link>
                        }
                    </div>
                </div>
            </div>
            <div
                className={props.collapse[props.category.category.id] ? "collapse show" : "collapse"}>

                {props.children}

            </div>
        </div>
    )
}

interface IMenuItemProps {
    item: treeItem
    dropLeft: string
    onDropLeft: (dropLeft: string) => void
    ref: React.MutableRefObject<null>
    setModalItem: (item: treeItem) => void
    setMenuInfo: React.Dispatch<React.SetStateAction<MenuTree>>
    lang: LangKey
    isCopy?: boolean
    stopList: IikoStopLists | null
}

function MenuItem(props: IMenuItemProps) {
    const {api, i18n} = useGlobalContext()
    const {menu, currentMenuId, iikoCompany, iikoActiveMenu} = useUserContext()
    const {
        iikoMenuSettings,
        iikoNomenclature,
        iikoExternalMenu,
        iikoExternalMenuItems
    } = useTypedSelector(state => state.iiko);
    const {rkeeperMenu, rkeeperMenuSettings} = useTypedSelector(state => state.rkeeper);

    const [deleteLoading, setDeleteLoading] = useState(false)
    const [hideShowLoading, setHideShowLoading] = useState(false)
    const [turnLoading, setTurnLoading] = useState(false)

    //todo

    function onDeleteProduct(id: string) {
        api.deleteItem({setLoading: setDeleteLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                props.setMenuInfo(menuInfo => {
                    return {
                        ...menuInfo,
                        sections: (menuInfo.sections || []).map(section => {
                            section.categories = (section.categories || []).map(category => {
                                category.items = (category.items || []).filter(item => item.id !== id)
                                return category
                            })
                            return section
                        })
                    }
                })
            }
        })
    }

    function onHideProduct(id: string) {
        api.hideItem({setLoading: setHideShowLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                addNotification({type: "success", message: i18n.product_hide()})
                // hide menu item
                props.setMenuInfo(menuInfo => {
                    return {
                        ...menuInfo,
                        sections: (menuInfo.sections || []).map(section => {
                            section.categories = (section.categories || []).map(category => {
                                category.items = (category.items || []).map(item => {
                                    if (item.id === id) {
                                        item.isHidden = true
                                    }
                                    return item
                                })
                                return category
                            })
                            return section
                        })
                    }
                })
            }
        })
    }

    function onShowProduct(id: string) {
        api.revealItem({setLoading: setHideShowLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                props.setMenuInfo(menuInfo => {
                    return {
                        ...menuInfo,
                        sections: (menuInfo.sections || []).map(section => {
                            section.categories = (section.categories || []).map(category => {
                                category.items = (category.items || []).map(item => {
                                    if (item.id === id) {
                                        item.isHidden = false
                                    }
                                    return item
                                })
                                return category
                            })
                            return section
                        })
                    }
                })
            }
        })
    }

    function onClickTurnOn(id: string) {
        api.turnOnItem({setLoading: setTurnLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                props.setMenuInfo(menuInfo => {
                    return {
                        ...menuInfo,
                        sections: (menuInfo.sections || []).map(section => {
                            section.categories = (section.categories || []).map(category => {
                                category.items = (category.items || []).map(item => {
                                    if (item.id === id) {
                                        item.isOff = false
                                    }
                                    return item
                                })
                                return category
                            })
                            return section
                        })
                    }
                })
            }
        })
    }

    function onClickTurnOff(id: string) {
        api.turnOffItem({setLoading: setTurnLoading}, id, currentMenuId).then(res => {
            if (res.success) {
                props.setMenuInfo(menuInfo => {
                    return {
                        ...menuInfo,
                        sections: (menuInfo.sections || []).map(section => {
                            section.categories = (section.categories || []).map(category => {
                                category.items = (category.items || []).map(item => {
                                    if (item.id === id) {
                                        item.isOff = true
                                    }
                                    return item
                                })
                                return category
                            })
                            return section
                        })
                    }
                })
            }
        })
    }

    return (
        <div className={"catalog__body"}>
            <div className="catalog__title">

                <Link to="#" className={`catalog__text ${props.item.isHidden ? "hidden-item" : ""}`}
                      onClick={(e) => {
                          e.preventDefault()
                          props.setModalItem(props.item)
                      }}>
                    <div
                        style={{
                            display: "flex",
                            flex: "inherit",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                            <Image className={'catalog__img'} alt={'image'}
                                   src={props.item.mainImage ? props.item.mainImage : ItemSquare}
                                   type={props.item.mainImage ? "menuItems" : undefined}
                            />
                            <p className={"cropText"}>
                                {props.isCopy ? <CopySVG title={i18n.paired_element()}/> : null}
                                {props.item.rkeeperBind ?
                                    <b style={{color: "blue"}} title={i18n.rkeeper_binding()}>r </b> : null}
                                {props.item.iikoBind && iikoActiveMenu ?
                                    <b style={{color: "red"}} title={i18n.iiko_binding()}>i </b> : null}
                                {props.item.name[props.lang]}</p>

                        </div>
                        <div className="tags" style={{width: "100%", marginLeft: "20px"}}>
                            {props.item.specs.tags[props.lang] ? props.item.specs.tags[props.lang]
                                    .split(",").map((tag, index) => {
                                        return (
                                            <div key={"tag" + props.item.id + index}
                                                 className={"tags__item"}>{tag}</div>
                                        )
                                    })
                                : null}
                        </div>
                        {!iikoCompany
                            ? props.item.isOff
                                ? <span className="disabled-item-hint">({i18n.sh_turned_off()})</span>
                                : null
                            : (!!props?.stopList?.stopLists?.[iikoMenuSettings?.iikoOrganizationId]?.[iikoMenuSettings?.iikoTerminalGroupId]?.find(item => item?.productId === iikoMenuSettings?.productBindings?.[props.item?.id])?.productId)
                                ? ((props?.stopList?.stopLists?.[iikoMenuSettings?.iikoOrganizationId]?.[iikoMenuSettings?.iikoTerminalGroupId]?.find(item => item?.productId === iikoMenuSettings?.productBindings?.[props.item?.id])?.balance || 0) <= 0)
                                    ? <span className="disabled-item-hint">({i18n.sh_turned_off()})</span>
                                    : null
                                : null
                        }
                        <div>
                            <span className="price-in-menu-row">
                                {props.item?.rkeeperBind
                                    ? `${rkeeperMenu.products?.find(product => product?.id === rkeeperMenuSettings.productBindings[props.item.id])?.price} ${CURRENCY[menu.i18n.currency]}`
                                    : ((isEmpty(iikoNomenclature) && isEmpty(iikoExternalMenu)) || isEmpty(iikoMenuSettings))
                                        ? `${props.item.prices.primary.price / 100} ${CURRENCY[menu.i18n.currency]}`
                                        : isEmpty(iikoNomenclature)
                                            ?
                                            `${iikoExternalMenuItems?.[iikoMenuSettings?.productBindings?.[props.item?.id]]?.itemSizes?.find(size => size?.isDefault)?.prices[0]?.price} ${CURRENCY[menu.i18n.currency]}`
                                            :
                                            !!iikoNomenclature?.products?.[iikoMenuSettings?.productBindings?.[props.item?.id]]?.data?.sizePrices?.find(size => size?.sizeId === null)?.price?.currentPrice
                                                ? `${iikoNomenclature?.products?.[iikoMenuSettings?.productBindings?.[props.item?.id]]?.data?.sizePrices?.find(size => size?.sizeId === null)?.price?.currentPrice} ${CURRENCY[menu.i18n.currency]}`
                                                : `${0} ${CURRENCY[menu.i18n.currency]}`
                                }
                            </span>
                        </div>
                    </div>
                </Link>
                <div
                    className={props.dropLeft === props.item.id ? "catalog__submenu dropleft show" : "catalog__submenu dropleft"}
                    ref={props.item.id === props.dropLeft ? props.ref : undefined}
                >
                    <Link to="#" className="catalog__submenu-toggle"
                          id={`ddl-${props.item.id}`}
                          aria-expanded={props.item.id === props.dropLeft}
                          onClick={() => {
                              props.onDropLeft(props.item.id)
                          }}
                    >
                    </Link>
                    <div
                        className={props.dropLeft === props.item.id ? "dropdown-menu dropdown-menu-left show" : "dropdown-menu dropdown-menu-left"}
                        tabIndex={0}>
                        <Link
                            to={RouteNames.MENUS_ITEM_EDIT.replace(":id", props.item.id)}
                            className="catalog__submenu-link"
                        >
                            <EditSVG/>
                            {i18n.edit()}
                        </Link>
                        <Link to="#"
                              className="catalog__submenu-link"
                              onClick={() => {
                                  addNotificationWithButton({
                                      message: i18n.q_delete_item(),
                                      onSubmit: () => onDeleteProduct(props.item.id),
                                      loading: deleteLoading,
                                      i18n,
                                  })
                              }}
                        >
                            <DeleteSVG/>
                            {i18n.delete()}
                        </Link>
                        {(!props.item.iikoBind && !props.item.rkeeperBind) ?
                            <>
                                {props.item.isOff ?
                                    <Link to="#"
                                          className="catalog__submenu-link"
                                          onClick={() => {
                                              onClickTurnOn(props.item.id)
                                          }}
                                    >
                                        <TurnOnSVG/>
                                        {i18n.turn_on()}
                                    </Link>
                                    :
                                    <Link to="#"
                                          className="catalog__submenu-link"
                                          onClick={() => {
                                              onClickTurnOff(props.item.id)
                                          }}
                                    >
                                        <TurnOffSVG/>
                                        {i18n.turn_off()}
                                    </Link>
                                }</> : null}

                        {props.item.isHidden ?
                            <Link to="#"
                                  className="catalog__submenu-link"
                                  onClick={() => {
                                      onShowProduct(props.item.id)
                                  }}
                            >
                                <ShowSVG/>
                                {i18n.show_in_menu()}
                            </Link>
                            :
                            <Link to="#"
                                  className="catalog__submenu-link"
                                  onClick={() => {
                                      onHideProduct(props.item.id)
                                  }}
                            >
                                <HideSVG/>
                                {i18n.hide_in_menu()}
                            </Link>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

const {Dragger} = Upload;

interface FileUploadModalProps {
    open: boolean
    setOpen: (open: boolean) => void
}

const FileUploadModal: FunctionComponent<FileUploadModalProps> = ({open, setOpen}) => {
    const navigate = useNavigate();
    const {currentMenuId} = useTypedSelector(state => state.user);
    const {currentLang} = useTypedSelector(state => state.lang);
    const {isLoadingExcelFile, excelFileUploadError} = useTypedSelector(state => state.menu);
    const {fillOutMenuFromExcel} = useActions();
    const [file, setFile] = useState<RcFile | null>(null);


    const props: UploadProps<File> = {
        name: 'file',
        multiple: false,
        fileList: file ? [file] as UploadFile<File>[] : [],
        onChange(info) {
            const {status} = info.file;
            if (status === 'done') {
                Notify.Info({title: txt.file_upload_success[currentLang], message: ""})
            } else if (status === 'error') {
                Notify.Warning({title: txt.file_upload_failed[currentLang], message: ""})
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files?.[0]);
        },
        beforeUpload(file) {
            setFile(file);
            return false;
        },
        onRemove() {
            setFile(null);
        },
        accept: ".xlsx, .xls"
    };

    const sendFile = async () => {
        if (!file) return;
        const formData = new FormData();
        formData.append("menuId", currentMenuId);
        formData.append("menuData", file as File);
        await fillOutMenuFromExcel(formData, {navigate});
    }

    useEffect(() => {
        if (excelFileUploadError.success) {
            setOpen(false);
            setFile(null);
        }
        // eslint-disable-next-line
    }, [excelFileUploadError.message]);

    return (
        <Modal
            open={open}
            title={txt.upload_excel_file[currentLang]}
            onCancel={() => setOpen(false)}
            cancelText={txt.cancel[currentLang]}
            onOk={sendFile}
            okText={txt.upload[currentLang]}
            confirmLoading={isLoadingExcelFile}
        >
            {(!!excelFileUploadError.message && excelFileUploadError.message !== "no errors") && (
                <div className="alert alert-warning py-4">
                    {excelFileUploadError.message}
                </div>
            )}

            <div className="alert alert-info py-4">
                <a
                    style={{textDecoration: "underline"}}
                    href="https://kamigroup.fra1.cdn.digitaloceanspaces.com/kami/other_resources/Example_import_Kami_items.xlsx"
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    {txt.template_of_excel_file_is_here[currentLang]}
                </a>
            </div>

            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">
                    {txt.upload_excel_file[currentLang]}
                </p>
                <p className="ant-upload-hint">
                    {txt.click_or_drag_file_to_this_area_to_upload[currentLang]}
                </p>
            </Dragger>
        </Modal>
    )
}
