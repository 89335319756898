// reducer.ts

import { SystemState, SystemAction, SystemActionEnum } from "./types";

const initialState: SystemState = {
    initialLocation: "/login",
    notificationApi: null,
    notificationContextHolder: null,
}

export default function systemReducer(state = initialState, action: SystemAction): SystemState {
    switch (action.type) {
        case SystemActionEnum.SET_INITIAL_LOCATION:
            return { ...state, initialLocation: action.payload };
        case SystemActionEnum.SET_NOTIFICATION_API:
            return {
                ...state,
                notificationApi: action.payload.api,
                notificationContextHolder: action.payload.contextHolder,
            };
        default:
            return state;
    }
}
