import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {RouteNames} from "migration/pages";
import {KamiMenuItem} from "migration/entities/kamiMenuStruct";
import {IikoProductId, ItemId} from "migration/entities/baseAliases";
import {IikoNomenclatureProduct} from "migration/entities/iikoNomenclature/iikoNomenclatureProduct";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Button} from "migration/shared/ui/Button";
import {Notify} from "migration/shared/lib/notification/notification";
import {Loading} from "migration/shared/ui/Loading";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {CURRENCY} from "../../../../../utils/options/options";
import {Breadcrumbs} from "../../../../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext, useUserContext} from "../../../../../hooks/globalContext";
import classes from "./IikoMenuBindings.module.scss";
import {IikoExternalMenuItem} from "../../../../entities/iikoExternalMenu";

export const IikoMenuBindings: FunctionComponent = () => {
    const navigate = useNavigate();
    const {i18n} = useGlobalContext();
    const {user, menu, currentMenuId, iikoActiveMenu} = useUserContext();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {menuData, isLoadingGetMenuData} = useTypedSelector(state => state.menuIiko);

    const {
        iikoNomenclature,
        iikoMenuSettings,
        iikoExternalMenu,
        iikoExternalMenuItems,
        isLoadingGetIikoMenuSettings,
        isLoadingGetNomenclature,
        isLoadingSaveIikoProductBindings,
    } = useTypedSelector(state => state.iiko);

    const {
        fetchIikoNomenclature,
        fetchMenuData,
        saveIikoProductBindings,
        setIsLoadingGetMenuData,
        fetchIikoExternalMenu,
        fetchIikoMenuSettings
    } = useActions();

    const [selectedKamiProduct, setSelectedKamiProduct] = useState<ItemId>("");
    const [selectedIikoProduct, setSelectedIikoProduct] = useState<IikoProductId>("");
    const [selectedProductBinding, setSelectedProductBinding] = useState<ItemId>("");
    const [searchQueryKami, setSearchQueryKami] = useState<string>("");
    const [searchQueryBindings, setSearchQueryBindings] = useState<string>("");
    const [searchQueryIiko, setSearchQueryIiko] = useState<string>("");

    const kamiProducts: KamiMenuItem[] = useMemo(() => {
        const products: KamiMenuItem[] = [];

        menuData?.sections?.forEach((section) => {
            section?.categories?.forEach((category) => {
                category?.items?.forEach((item) => {
                    products.push(item);
                })
            })
        })

        const filteredProducts = products.filter(item => {
            return !Object.keys(iikoMenuSettings?.productBindings || {}).includes(item.id);
        });

        if (searchQueryKami) {
            return filteredProducts.filter((item) => {
                return item?.name?.[menu?.i18n?.defaultLang]?.toLowerCase().includes(searchQueryKami.toLowerCase());
            });
        }

        return filteredProducts || [];
    }, [menuData, menu, searchQueryKami, iikoMenuSettings]);

    const productBindings = useMemo(() => {
        const bindigns: {
            kamiProduct: KamiMenuItem,
            iikoProduct: IikoNomenclatureProduct | IikoExternalMenuItem
        }[] = [];

        const kamiMenuProducts: KamiMenuItem[] = [];

        menuData?.sections?.forEach((section) => {
            section?.categories?.forEach((category) => {
                category?.items?.forEach((item) => {
                    kamiMenuProducts.push(item);
                })
            })
        })

        Object.keys(iikoMenuSettings?.productBindings || {}).forEach((kamiProductId) => {
            const iikoProductId = iikoMenuSettings?.productBindings[kamiProductId];
            const kamiProduct = kamiMenuProducts.find((item) => item.id === kamiProductId) || {id: kamiProductId} as KamiMenuItem;
            let iikoProduct
            if (iikoMenuSettings.iikoExternalMenuId) {
                iikoProduct = iikoExternalMenuItems[iikoProductId] || {id: iikoProductId};
            } else {
                iikoProduct = iikoNomenclature?.products?.[iikoProductId] || {id: iikoProductId};
            }
            if (!kamiProduct || !iikoProduct) {
                return;
            }
            bindigns.push({kamiProduct, iikoProduct});
        });

        if (searchQueryBindings) {
            return bindigns.filter((item) => {
                if (iikoMenuSettings.iikoExternalMenuId) {
                    return item?.kamiProduct?.name?.[menu?.i18n?.defaultLang]?.toLowerCase().includes(searchQueryBindings.toLowerCase()) ||
                        (item?.iikoProduct as IikoExternalMenuItem)?.name?.toLowerCase().includes(searchQueryBindings.toLowerCase());
                } else {
                    return item?.kamiProduct?.name?.[menu?.i18n?.defaultLang]?.toLowerCase().includes(searchQueryBindings.toLowerCase()) ||
                        (item?.iikoProduct as IikoNomenclatureProduct)?.data?.name?.toLowerCase().includes(searchQueryBindings.toLowerCase());
                }
            });
        }

        return bindigns || [];
    }, [iikoMenuSettings, iikoNomenclature, menu, searchQueryBindings, menuData]);

    const uniqueIikoProducts: (IikoNomenclatureProduct | IikoExternalMenuItem)[] = useMemo(() => {
        const iikoProducts: (IikoNomenclatureProduct | IikoExternalMenuItem)[] = [];

        if (iikoMenuSettings.iikoExternalMenuId) {
            Object.keys(iikoExternalMenuItems || {}).filter(function (item, pos, self) {
                return self.indexOf(item) === pos;
            }).forEach((productId) => {
                if (Object.values(iikoMenuSettings?.productBindings).includes(productId)) {
                    return;
                }
                iikoProducts.push(iikoExternalMenuItems[productId]);
            });
        } else {
            Object.keys(iikoNomenclature?.products || {}).filter(function (item, pos, self) {
                return self.indexOf(item) === pos;
            }).forEach((productId) => {
                if (Object.values(iikoMenuSettings?.productBindings).includes(productId)) {
                    return;
                }
                iikoProducts.push(iikoNomenclature.products[productId]);
            });
        }

        const filteredIikoProducts = iikoProducts.filter(item => {
            if (iikoMenuSettings.iikoExternalMenuId) {
                return !Object.keys(iikoMenuSettings?.productBindings || {}).includes((item as IikoExternalMenuItem).itemId);
            } else {
                return !Object.values(iikoMenuSettings?.productBindings || {}).includes((item as IikoNomenclatureProduct).id);
            }
        });

        if (searchQueryIiko) {
            return filteredIikoProducts.filter((item) => {
                if (iikoMenuSettings.iikoExternalMenuId) {
                    return (item as IikoExternalMenuItem)?.name?.toLowerCase().includes(searchQueryIiko.toLowerCase());
                } else {
                    return (item as IikoNomenclatureProduct)?.data?.name?.toLowerCase().includes(searchQueryIiko.toLowerCase());
                }
            });
        }

        return filteredIikoProducts || {};
    }, [iikoNomenclature, iikoMenuSettings, searchQueryIiko]);

    const handleSelectKamiProduct = (kamiProductId: ItemId) => {
        if (selectedKamiProduct === kamiProductId) {
            setSelectedKamiProduct("");
            return;
        }
        setSelectedProductBinding("");
        setSelectedKamiProduct(kamiProductId);
    }

    const handleSelectProductBinding = (kamiProductId: ItemId) => {
        if (selectedProductBinding === kamiProductId) {
            setSelectedProductBinding("");
            return;
        }
        setSelectedIikoProduct("");
        setSelectedKamiProduct("");
        setSelectedProductBinding(kamiProductId);
    }

    const handleSelectIikoProduct = (iikoProductId: IikoProductId) => {
        if (selectedIikoProduct === iikoProductId) {
            setSelectedIikoProduct("");
            return;
        }
        setSelectedProductBinding("");
        setSelectedIikoProduct(iikoProductId);
    }

    const bindProduct = async () => {
        if (!selectedKamiProduct || !selectedIikoProduct) {
            Notify.Info({title: txt.please_select_kami_and_iiko_product[currentLang], message: ""})
            return;
        }

        setIsLoadingGetMenuData(true);
        await saveIikoProductBindings(
            {
                "menuId": currentMenuId,
                "organizationId": user?.org?.id || "",
                "productBindings": {
                    ...iikoMenuSettings?.productBindings,
                    [selectedKamiProduct]: selectedIikoProduct
                }
            },
            {navigate: navigate},
            true
        );
        setIsLoadingGetMenuData(false);
        setSelectedIikoProduct("");
        setSelectedKamiProduct("");
    }

    const unBindProduct = async (kamiProductId: ItemId) => {
        if (!kamiProductId) {
            Notify.Info({title: txt.please_select_product_binding[currentLang], message: ""})
            return;
        }

        const bindings = {...iikoMenuSettings?.productBindings};
        delete bindings[kamiProductId];
        setIsLoadingGetMenuData(true);
        await saveIikoProductBindings(
            {
                "menuId": currentMenuId,
                "organizationId": user?.org?.id || "",
                "productBindings": bindings
            },
            {navigate: navigate},
            false
        );
        setIsLoadingGetMenuData(false);
        setSelectedProductBinding("");
    }

    useEffect(() => {
        if (iikoActiveMenu) {
            const controller = new AbortController();
            fetchIikoMenuSettings({menuId: currentMenuId}, controller, {navigate: navigate});
            return () => controller.abort();
        }
    }, [iikoActiveMenu]);

    useEffect(() => {
        const controller = new AbortController();
        if (iikoMenuSettings.iikoExternalMenuId) {
            fetchIikoExternalMenu({
                iikoExternalMenuId: iikoMenuSettings.iikoExternalMenuId,
                iikoPriceCategoryId: iikoMenuSettings.iikoPriceCategoryId || null,
                organizationId: user?.org?.id || "",
                iikoOrganizationId: iikoMenuSettings?.iikoOrganizationId
            }, controller, {navigate: navigate});
        } else {
            fetchIikoNomenclature({
                organizationId: user?.org?.id || "",
                iikoOrganizationId: iikoMenuSettings?.iikoOrganizationId
            }, controller, {navigate: navigate});
        }
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iikoMenuSettings]);

    useEffect(() => {
        const controller = new AbortController();
        fetchMenuData({menuId: currentMenuId}, controller, {navigate: navigate}, true);
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <Breadcrumbs
                    infos={[
                        {title: i18n.menus(), link: RouteNames.MENUS, index: "bracer-0"},
                        {
                            title: i18n.iiko_product_bindings(),
                            link: RouteNames.MENUS_IIKO_PRODUCT_BINDINGS,
                            index: "bracer-1"
                        }
                    ]}
                />

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.iiko_product_bindings()}</h1>
                    </div>

                    <div className={"app__header-right"} style={{display: "flex", gap: "20px"}}>
                        <Button
                            text={txt.unbind[currentLang]}
                            onClick={() => unBindProduct(selectedProductBinding)}
                            disabled={!selectedProductBinding}
                            isFilled={true}
                            isDelete={true}
                            loading={isLoadingSaveIikoProductBindings}
                        />
                        <Button
                            text={txt.bind[currentLang]}
                            onClick={bindProduct}
                            disabled={!selectedKamiProduct || !selectedIikoProduct}
                            isFilled={true}
                            loading={isLoadingSaveIikoProductBindings}
                        />
                    </div>
                </div>

                <div className={classes.card}>
                    <div className={classes.table}>
                        <div className={classes.table__column}>
                            <div className={classes.table__column__header}>
                                {`${txt.kami_products[currentLang]} - ${kamiProducts?.length || 0}`}
                            </div>

                            <input
                                value={searchQueryKami}
                                onChange={(e) => setSearchQueryKami(e.target.value)}
                                placeholder={txt.search_kami_products_by_name[currentLang]}
                                className={classes.table__column__searchbar__input}
                            />

                            <div
                                className={classes.table__column__products}
                                style={{
                                    justifyContent: isLoadingGetMenuData ? "center" : "",
                                    height: isLoadingGetMenuData ? "100%" : ""
                                }}
                            >
                                {isLoadingGetMenuData
                                    ?
                                    <Loading/>
                                    :
                                    !!kamiProducts?.length
                                        ?
                                        kamiProducts.map(item => {
                                            return (
                                                <div
                                                    key={item?.id}
                                                    onClick={() => handleSelectKamiProduct(item?.id)}
                                                    className={classes.product}
                                                    style={{
                                                        backgroundColor: selectedKamiProduct === item?.id ? "#0252CC" : "",
                                                        color: selectedKamiProduct === item?.id ? "#fff" : ""
                                                    }}
                                                >
                                                    <div className={classes.product__title}>
                                                        {item?.name?.[menu?.i18n?.defaultLang]}
                                                    </div>
                                                    <div className={classes.product__price}>
                                                        {`${item?.prices?.primary?.price / 100} ${CURRENCY[menu.i18n.currency]}`}
                                                    </div>
                                                    <div className={classes.product__id}>
                                                        {item?.id}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className={classes.empty__list}>
                                            {txt.empty[currentLang]}
                                        </div>
                                }
                            </div>
                        </div>
                        <div className={classes.table__column}>
                            <div className={classes.table__column__header}>
                                {`${txt.bindings[currentLang]} - ${productBindings?.length || 0}`}
                            </div>

                            <input
                                value={searchQueryBindings}
                                onChange={(e) => setSearchQueryBindings(e.target.value)}
                                placeholder={txt.search_bindings_by_product_name[currentLang]}
                                className={classes.table__column__searchbar__input}
                            />

                            <div
                                className={classes.table__column__products}
                                style={{
                                    justifyContent: (isLoadingGetIikoMenuSettings || isLoadingGetNomenclature) ? "center" : "",
                                    height: (isLoadingGetIikoMenuSettings || isLoadingGetNomenclature) ? "100%" : ""
                                }}
                            >
                                {isLoadingGetIikoMenuSettings
                                    ?
                                    <Loading/>
                                    :
                                    !!productBindings.length
                                        ?
                                        productBindings.map((binding, index) => {
                                            return (
                                                <div
                                                    key={
                                                        iikoMenuSettings.iikoExternalMenuId
                                                            ? `${binding?.kamiProduct?.id} - ${(binding.iikoProduct as IikoExternalMenuItem)?.itemId} - ${index}`
                                                            : `${binding?.kamiProduct?.id} - ${(binding.iikoProduct as IikoNomenclatureProduct)?.id} - ${index}`
                                                    }
                                                    onClick={() => handleSelectProductBinding(binding?.kamiProduct?.id)}
                                                    className={classes.product__card}
                                                    style={{
                                                        marginBottom: "10px",
                                                        backgroundColor: selectedProductBinding === binding?.kamiProduct?.id ? "#0252CC" : "",
                                                        color: selectedProductBinding === binding?.kamiProduct?.id ? "#fff" : ""
                                                    }}
                                                >
                                                    <div
                                                        className={classes.product}
                                                        style={{borderBottom: "1px dashed #E5E5E5"}}
                                                    >
                                                        <div className={classes.product__title}>
                                                            {"KAMI"}
                                                        </div>
                                                        <div className={classes.product__title}>
                                                            {binding?.kamiProduct?.name?.[menu?.i18n?.defaultLang] || txt.product_not_found[currentLang]}
                                                        </div>
                                                        {!!binding?.kamiProduct?.prices?.primary?.price && (
                                                            <div className={classes.product__price}>
                                                                {`${binding?.kamiProduct?.prices?.primary?.price / 100} ${CURRENCY[menu.i18n.currency]}`}
                                                            </div>
                                                        )}
                                                        <div className={classes.product__id} style={{opacity: "1"}}>
                                                            {binding?.kamiProduct?.id}
                                                        </div>
                                                    </div>

                                                    {/*<React.Fragment>*/}
                                                    {/*    <div className={classes.product__card__title}>{"kami"}</div>*/}
                                                    {/*    <VerticalAlignMiddleOutlined*/}
                                                    {/*        className={classes.product__card__icon}/>*/}
                                                    {/*    <div className={classes.product__card__title}>{"iiko"}</div>*/}
                                                    {/*</React.Fragment>*/}

                                                    <div
                                                        className={classes.product}
                                                        style={{color: selectedProductBinding === binding?.kamiProduct?.id ? "#fff" : "#fc393d"}}
                                                    >
                                                        <div className={classes.product__title}>
                                                            {"IIKO"}
                                                        </div>
                                                        <div className={classes.product__title}>
                                                            {
                                                                iikoMenuSettings.iikoExternalMenuId
                                                                    ? (binding.iikoProduct as IikoExternalMenuItem)?.name || txt.product_not_found[currentLang]
                                                                    : (binding.iikoProduct as IikoNomenclatureProduct)?.data?.name || txt.product_not_found[currentLang]
                                                            }
                                                        </div>
                                                        {iikoMenuSettings.iikoExternalMenuId ? (
                                                            (!!(binding?.iikoProduct as IikoExternalMenuItem)?.itemSizes?.find(size => size?.sizeId === null)?.prices[0].price || !!(binding?.iikoProduct as IikoExternalMenuItem)?.itemSizes?.[0]?.prices[0].price || 0) && (
                                                                <div className={classes.product__price}>
                                                                    {`${(binding?.iikoProduct as IikoExternalMenuItem)?.itemSizes?.find(size => size?.sizeId === null)?.prices[0].price || (binding?.iikoProduct as IikoExternalMenuItem)?.itemSizes?.[0]?.prices[0].price || 0} ${CURRENCY[menu.i18n.currency]}`}
                                                                </div>
                                                            )
                                                        ) : (
                                                            (!!(binding?.iikoProduct as IikoNomenclatureProduct)?.data?.sizePrices?.find(size => size?.sizeId === null)?.price?.currentPrice || !!(binding?.iikoProduct as IikoNomenclatureProduct)?.data?.sizePrices?.[0]?.price?.currentPrice || 0) && (
                                                                <div className={classes.product__price}>
                                                                    {`${(binding?.iikoProduct as IikoNomenclatureProduct)?.data?.sizePrices?.find(size => size?.sizeId === null)?.price?.currentPrice || (binding?.iikoProduct as IikoNomenclatureProduct)?.data?.sizePrices?.[0]?.price?.currentPrice || 0} ${CURRENCY[menu.i18n.currency]}`}
                                                                </div>
                                                            )
                                                        )
                                                        }
                                                        <div className={classes.product__id} style={{opacity: "1"}}>
                                                            {
                                                                iikoMenuSettings.iikoExternalMenuId
                                                                    ? (binding.iikoProduct as IikoExternalMenuItem)?.itemId
                                                                    : (binding.iikoProduct as IikoNomenclatureProduct)?.id
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className={classes.empty__list}>
                                            {txt.empty[currentLang]}
                                        </div>
                                }
                            </div>
                        </div>
                        <div className={classes.table__column}>
                            <div className={classes.table__column__header}>
                                {`${txt.iiko_products[currentLang]} - ${uniqueIikoProducts?.length || 0}`}
                            </div>

                            <input
                                value={searchQueryIiko}
                                onChange={(e) => setSearchQueryIiko(e.target.value)}
                                placeholder={txt.search_iiko_products_by_name[currentLang]}
                                className={classes.table__column__searchbar__input}
                            />

                            <div
                                className={classes.table__column__products}
                                style={{
                                    justifyContent: (isLoadingGetIikoMenuSettings || isLoadingGetNomenclature) ? "center" : "",
                                    height: (isLoadingGetIikoMenuSettings || isLoadingGetNomenclature) ? "100%" : ""
                                }}
                            >
                                {(isLoadingGetIikoMenuSettings || isLoadingGetNomenclature)
                                    ?
                                    <Loading/>
                                    :
                                    !!uniqueIikoProducts.length
                                        ?
                                        uniqueIikoProducts.map((iikoProduct) => {
                                            if (iikoMenuSettings.iikoExternalMenuId) {
                                                return (
                                                    <div
                                                        key={(iikoProduct as IikoExternalMenuItem)?.itemId}
                                                        onClick={() => handleSelectIikoProduct((iikoProduct as IikoExternalMenuItem)?.itemId)}
                                                        className={classes.product}
                                                        style={{
                                                            backgroundColor: selectedIikoProduct === (iikoProduct as IikoExternalMenuItem)?.itemId ? "#0252CC" : "",
                                                            color: selectedIikoProduct === (iikoProduct as IikoExternalMenuItem)?.itemId ? "#fff" : ""
                                                        }}
                                                    >
                                                        <div className={classes.product__title}>
                                                            {(iikoProduct as IikoExternalMenuItem)?.name}
                                                        </div>
                                                        <div className={classes.product__price}>
                                                            {`${(iikoProduct as IikoExternalMenuItem)?.itemSizes?.find(size => size?.sizeId === null)?.prices[0].price || (iikoProduct as IikoExternalMenuItem)?.itemSizes?.[0]?.prices[0].price || 0} ${CURRENCY[menu.i18n.currency]}`}
                                                        </div>
                                                        <div className={classes.product__id}>
                                                            {(iikoProduct as IikoExternalMenuItem)?.itemId}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div
                                                    key={(iikoProduct as IikoNomenclatureProduct)?.id}
                                                    onClick={() => handleSelectIikoProduct((iikoProduct as IikoNomenclatureProduct)?.id)}
                                                    className={classes.product}
                                                    style={{
                                                        backgroundColor: selectedIikoProduct === (iikoProduct as IikoNomenclatureProduct)?.id ? "#0252CC" : "",
                                                        color: selectedIikoProduct === (iikoProduct as IikoNomenclatureProduct)?.id ? "#fff" : ""
                                                    }}
                                                >
                                                    <div className={classes.product__title}>
                                                        {(iikoProduct as IikoNomenclatureProduct)?.data?.name}
                                                    </div>
                                                    <div className={classes.product__price}>
                                                        {`${(iikoProduct as IikoNomenclatureProduct)?.data?.sizePrices?.find(size => size?.sizeId === null)?.price?.currentPrice || (iikoProduct as IikoNomenclatureProduct)?.data?.sizePrices?.[0]?.price?.currentPrice || 0} ${CURRENCY[menu.i18n.currency]}`}
                                                    </div>
                                                    <div className={classes.product__id}>
                                                        {(iikoProduct as IikoNomenclatureProduct)?.id}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className={classes.empty__list}>
                                            {txt.empty[currentLang]}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
