import React, {useEffect, useMemo, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {useIIkoCopyByMenu} from "../../hooks/menu/iiko";
import {Checkbox} from "../../components/input/checkbox";
import {MenuUlWithLoader} from "../../components/loader/menuUlWithLoader";
import {useTypedSelector} from "../../migration/shared/lib/hooks/useTypedSelector";
import {useActions} from "../../migration/shared/lib/hooks/useActions";
import {IikoProductCategoryId, IikoProductId} from "../../migration/entities/baseAliases";
import {isEmpty} from "lodash";
import {IikoNomenclatureProduct} from "../../migration/entities/iikoNomenclature/iikoNomenclatureProduct";
import {
    IikoNomenclatureProductCategory
} from "../../migration/entities/iikoNomenclature/iikoNomenclatureProductCategory";
import {Notify} from "../../migration/shared/lib/notification/notification";
import {RouteNames} from "../../migration/pages";
import {
    IikoExternalMenu,
    IikoExternalMenuCategory,
    IikoExternalMenuItem
} from "../../migration/entities/iikoExternalMenu";

export interface IikoCategoriesWithProducts {
    [categoryId: IikoProductCategoryId]: IikoProductId[]
}

export function MenuCopyIIKO() {
    const navigate = useNavigate();
    const {i18n, userInfoLoading} = useGlobalContext()
    const {currentMenuId, user} = useUserContext();
    const info = useIIkoCopyByMenu();
    const {
        iikoNomenclature,
        iikoMenuSettings,
        isLoadingGetIikoMenuSettings,
        isLoadingGetNomenclature,
        isLoadingImportIikoProductBindings,
        iikoExternalMenu,
        iikoExternalMenuItems
    } = useTypedSelector(state => state.iiko);
    const {fetchIikoMenuSettings, fetchIikoNomenclature, importIikoProducts, fetchIikoExternalMenu} = useActions();


    const uniqueIikoProductCategories:
        { [iikoProductCategoryId: IikoProductCategoryId]: IikoExternalMenuCategory } |
        { [iikoProductCategoryId: IikoProductCategoryId]: IikoNomenclatureProductCategory } = useMemo(
        () => {
            if (iikoMenuSettings.iikoExternalMenuId ) {
                const iikoProductCategories: {
                    [iikoProductCategoryId: IikoProductCategoryId]: IikoExternalMenuCategory
                } = {};

                iikoExternalMenu?.itemCategories.filter(function (item, pos, self) {
                    return self.indexOf(item) === pos;
                }).forEach((item) => {
                    const iikoProductCategory = iikoExternalMenu.itemCategories?.find((category) => category.id === item.id);
                    if (!!iikoProductCategory) {
                        iikoProductCategories[item.id] = iikoProductCategory;
                    }
                });

                return iikoProductCategories;
            } else {
                const iikoProductCategories: {
                    [iikoProductCategoryId: IikoProductCategoryId]: IikoNomenclatureProductCategory
                } = {};

                Object.keys(iikoNomenclature?.productCategories || {}).filter(function (item, pos, self) {
                    return self.indexOf(item) === pos;
                }).forEach((categoryId) => {
                    iikoProductCategories[categoryId] = iikoNomenclature.productCategories[categoryId];
                });

                return iikoProductCategories;
            }
        }, [iikoNomenclature, iikoExternalMenu]
    );

    const uniqueIikoProducts:
        { [iikoProductId: IikoProductId]: IikoNomenclatureProduct } |
        { [iikoProductId: IikoProductId]: IikoExternalMenuItem } = useMemo(
        () => {
            if (iikoMenuSettings.iikoExternalMenuId ) {
                const iikoProducts: { [iikoProductId: IikoProductId]: IikoExternalMenuItem } = {};

                Object.keys(iikoExternalMenuItems || {}).filter(function (item, pos, self) {
                    return self.indexOf(item) === pos;
                }).forEach((productId) => {
                    if (Object.values(iikoMenuSettings?.productBindings).includes(productId)) {
                        return;
                    }
                    iikoProducts[productId] = iikoExternalMenuItems[productId];
                });

                return iikoProducts;
            } else {
                const iikoProducts: { [iikoProductId: IikoProductId]: IikoNomenclatureProduct } = {};

                Object.keys(iikoNomenclature?.products || {}).filter(function (item, pos, self) {
                    return self.indexOf(item) === pos;
                }).forEach((productId) => {
                    if (Object.values(iikoMenuSettings?.productBindings).includes(productId)) {
                        return;
                    }
                    iikoProducts[productId] = iikoNomenclature.products[productId];
                });

                return iikoProducts;
            }
        }, [iikoNomenclature, iikoExternalMenu]
    );

    const iikoCategoriesWithProducts: IikoCategoriesWithProducts = useMemo(() => {
        const iikoCategoriesWithProducts: IikoCategoriesWithProducts = {};

        iikoCategoriesWithProducts[""] = [];
        Object.keys(uniqueIikoProductCategories || {}).forEach((categoryId) => {
            iikoCategoriesWithProducts[categoryId] = [];

            Object.keys(uniqueIikoProducts || {}).forEach((productId) => {
                const product = uniqueIikoProducts[productId];
                if (iikoMenuSettings.iikoExternalMenuId ) {
                    const productCategoryId = iikoExternalMenu?.itemCategories.find((category) => category.items.map(item => item.itemId).includes((product as IikoExternalMenuItem).itemId));
                    if (categoryId === (productCategoryId?.id || "")) {
                        iikoCategoriesWithProducts[categoryId].push(productId);
                    }
                } else {
                    if (categoryId === (product as IikoNomenclatureProduct)?.data?.productCategoryId) {
                        iikoCategoriesWithProducts[categoryId].push(productId);
                    }
                }
            });
        });

        Object.keys(uniqueIikoProducts || {}).forEach((productId) => {
            const product = uniqueIikoProducts[productId];
            if (iikoMenuSettings.iikoExternalMenuId ) {
                if (!(product as IikoExternalMenuItem)?.productCategoryId) {
                    // iikoCategoriesWithProducts[""].push(productId);
                }
            } else {
                if (!(product as IikoNomenclatureProduct)?.data?.productCategoryId) {
                    iikoCategoriesWithProducts[""].push(productId);
                }
            }
        });

        const filteredIikoCategoriesWithProducts: IikoCategoriesWithProducts = {};
        if (!isEmpty(uniqueIikoProducts)) {
            Object.keys(iikoCategoriesWithProducts).forEach((categoryId) => {
                if (iikoCategoriesWithProducts[categoryId].length > 0) {
                    filteredIikoCategoriesWithProducts[categoryId] = iikoCategoriesWithProducts[categoryId];
                }
            })
        }

        return filteredIikoCategoriesWithProducts || {};
    }, [uniqueIikoProductCategories, uniqueIikoProducts]);

    const [selectedProducts, setSelectedProducts] = useState<(IikoExternalMenuItem | IikoNomenclatureProduct)[]>([]);

    const numberOfSelectedProductsInCategory = (categoryId: IikoProductCategoryId) => {
        return selectedProducts?.filter((p) => {
            if (iikoMenuSettings.iikoExternalMenuId ) {
                if (!(p as IikoExternalMenuItem)?.productCategoryId && categoryId === "") {
                    return true;
                }
                return (p as IikoExternalMenuItem)?.productCategoryId === categoryId
            } else {
                if (!(p as IikoNomenclatureProduct).data?.productCategoryId && categoryId === "") {
                    return true;
                }
                return (p as IikoNomenclatureProduct).data?.productCategoryId === categoryId
            }
        })?.length || 0
    }

    const handleSelectProduct = (product: IikoNomenclatureProduct) => {
        if (iikoMenuSettings.iikoExternalMenuId ) {
            if (selectedProducts.map((p) => (p as IikoExternalMenuItem).itemId).includes(product.id)) {
                setSelectedProducts(selectedProducts.filter((p) => (p as IikoExternalMenuItem).itemId !== product.id));
                return;
            }
        } else {
            if (selectedProducts.map((p) => (p as IikoNomenclatureProduct).id).includes(product.id)) {
                setSelectedProducts(selectedProducts.filter((p) => (p as IikoNomenclatureProduct).id !== product.id));
                return;
            }
        }
        setSelectedProducts([...selectedProducts, product]);
    }

    const handleSelectAllProducts = () => {
        if (selectedProducts.length === Object.keys(uniqueIikoProducts).length) {
            setSelectedProducts([]);
            return;
        }

        if (iikoMenuSettings.iikoExternalMenuId ) {
            const allProducts: IikoExternalMenuItem[] = [];
            Object.keys(iikoCategoriesWithProducts).forEach((categoryId) => {
                iikoCategoriesWithProducts[categoryId].forEach((productId) => {
                    allProducts.push(uniqueIikoProducts[productId] as IikoExternalMenuItem);
                });
            });
            setSelectedProducts(allProducts);
        } else {
            const allProducts: IikoNomenclatureProduct[] = [];
            Object.keys(iikoCategoriesWithProducts).forEach((categoryId) => {
                iikoCategoriesWithProducts[categoryId].forEach((productId) => {
                    allProducts.push(uniqueIikoProducts[productId] as IikoNomenclatureProduct);
                });
            });
            setSelectedProducts(allProducts);
        }
    }

    const handleSelectUniqueProducts = () => {
        const productsNames: string[] = [];
        Object.keys(iikoCategoriesWithProducts).forEach((categoryId) => {
            iikoCategoriesWithProducts[categoryId].forEach((productId) => {
                if (iikoMenuSettings.iikoExternalMenuId ) {
                    productsNames.push((uniqueIikoProducts[productId] as IikoExternalMenuItem)?.name);
                } else {
                    productsNames.push((uniqueIikoProducts[productId] as IikoNomenclatureProduct).data?.name);
                }
            });
        });

        const uniqueProductsNames = productsNames.filter(function (item, pos) {
            return productsNames.indexOf(item) === pos;
        });

        // filter uniqueIikoProducts by name
        const uniqueProducts: (IikoNomenclatureProduct | IikoExternalMenuItem)[] = [];
        Object.keys(uniqueIikoProducts).forEach((productId) => {
            if (iikoMenuSettings.iikoExternalMenuId ) {
                const product = uniqueIikoProducts[productId] as IikoExternalMenuItem;
                if (uniqueProductsNames.includes(product?.name)) {
                    if (uniqueProducts.map(p => (p as IikoExternalMenuItem)?.name).includes(product?.name)) {
                        return;
                    }
                    uniqueProducts.push(product);
                }
            } else {
                const product = uniqueIikoProducts[productId] as IikoNomenclatureProduct;
                if (uniqueProductsNames.includes(product.data?.name)) {
                    if (uniqueProducts.map(p => (p as IikoNomenclatureProduct).data?.name).includes(product.data?.name)) {
                        return;
                    }
                    uniqueProducts.push(product);
                }
            }
        });

        if (uniqueProducts.length === selectedProducts.length) {
            setSelectedProducts([]);
            return;
        }

        setSelectedProducts(uniqueProducts);
    }

    const copyProducts = () => {
        if (selectedProducts?.length === 0) {
            Notify.Info({title: i18n.please_select_products(), message: ""})
            return;
        }

        const iikoProducts: { [iikoProductCategoryId: IikoProductCategoryId]: IikoProductId[] } = {};
        Object.keys(iikoCategoriesWithProducts).forEach((categoryId) => {
            iikoProducts[categoryId] = [];
            iikoCategoriesWithProducts[categoryId].forEach((productId) => {
                if (iikoMenuSettings.iikoExternalMenuId ) {
                    if (selectedProducts.map((p) => (p as IikoExternalMenuItem).itemId).includes(productId)) {
                        iikoProducts[categoryId].push(productId);
                    }
                } else {
                    if (selectedProducts.map((p) => (p as IikoNomenclatureProduct).id).includes(productId)) {
                        iikoProducts[categoryId].push(productId);
                    }
                }
            });
        });

        const filteredIikoProducts: { [iikoProductCategoryId: IikoProductCategoryId]: IikoProductId[] } = {};
        Object.keys(iikoProducts).forEach((categoryId) => {
            if (iikoProducts[categoryId].length > 0) {
                filteredIikoProducts[categoryId] = iikoProducts[categoryId];
            }
        });

        importIikoProducts(
            {
                "menuId": currentMenuId,
                "organizationId": user?.org?.id || "",
                "iikoProducts": filteredIikoProducts
            },
            {navigate: navigate}
        );
        setSelectedProducts([]);
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchIikoMenuSettings({menuId: currentMenuId}, controller, {navigate: navigate});
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iikoMenuSettings?.iikoOrganizationId) {
            return;
        }

        const controller = new AbortController();
        if (iikoMenuSettings.iikoExternalMenuId ) {
            fetchIikoExternalMenu({
                organizationId: user?.org?.id || "",
                iikoOrganizationId: iikoMenuSettings?.iikoOrganizationId,
                iikoExternalMenuId: iikoMenuSettings?.iikoExternalMenuId,
                iikoPriceCategoryId: iikoMenuSettings?.iikoPriceCategoryId || null,
            }, controller, {navigate: navigate});
        } else {
            fetchIikoNomenclature({
                organizationId: user?.org?.id || "",
                iikoOrganizationId: iikoMenuSettings?.iikoOrganizationId
            }, controller, {navigate: navigate});
        }
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iikoMenuSettings]);

    useEffect(() => {
        document.title = i18n.menus()
    }, [i18n]);

    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">
                <Breadcrumbs
                    infos={[
                        {title: i18n.menus(), link: RouteNames.MENUS, index: "bracer-0"},
                        {title: i18n.coping_from_iiko(), link: RouteNames.MENUS_COPY_IIKO, index: "bracer-1"}
                    ]}
                />

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.coping_from_iiko()}</h1>
                    </div>
                    <div className="app__header-right" style={{display: "flex"}}>
                        <Link to={RouteNames.MENUS} className="button -blue">
                            {i18n.cancel()}
                        </Link>
                        <button className="button -blue" onClick={handleSelectUniqueProducts}>
                            {i18n.check_unique()}
                        </button>
                        <button className="button -blue" onClick={handleSelectAllProducts}>
                            {i18n.check_all()}
                        </button>
                        <button className="button -blue" onClick={copyProducts}>
                            {i18n.copy_iiko()}
                        </button>
                    </div>
                </div>

                <div style={{width: "100%", display: "flex", alignItems: "center", marginBottom: "20px"}}>
                    <div style={{display: "flex", alignItems: "center", fontSize: "18px", fontWeight: "500"}}>
                        <div>{`${i18n.selected_products_amount()}: ${selectedProducts?.length || 0}`}</div>
                    </div>
                </div>

                <MenuUlWithLoader
                    id="catalog"
                    className="catalog"
                    loading={isLoadingGetIikoMenuSettings || isLoadingGetNomenclature || isLoadingImportIikoProductBindings}
                    count={2}
                    level={2}
                    notFound={Object.keys(iikoCategoriesWithProducts).length === 0 && (isLoadingGetNomenclature)}
                    notFoundText={i18n.help_empty_iiko_items()}
                >

                    {!!Object.keys(iikoCategoriesWithProducts || {}).length
                        ?
                        (Object.keys(iikoCategoriesWithProducts || {})?.map((categoryId, index) => {
                            return (
                                <li className={"catalog__item"} key={categoryId}>
                                    <div className={"catalog__title"}>
                                        <Link
                                            to={"#"}
                                            className={info.collapse[categoryId] ? `catalog__text collapsed` : `catalog__text`}
                                            aria-expanded={info.collapse[categoryId] ? "true" : "false"}
                                            onClick={() => info.onCollapse(categoryId)}
                                        >
                                            {
                                                iikoMenuSettings.iikoExternalMenuId
                                                    ? iikoExternalMenu?.itemCategories.find((category) => category.id === categoryId)?.name || i18n.without_name()
                                                    : iikoNomenclature?.productCategories[categoryId]?.data?.name || i18n.without_name()
                                            }
                                            <span className="catalog__subtext">
                                                ({iikoCategoriesWithProducts[categoryId]?.length || 0})
                                            </span>
                                            {numberOfSelectedProductsInCategory(categoryId) > 0 && (
                                                <div
                                                    style={{
                                                        width: "23px",
                                                        height: "23px",
                                                        backgroundColor: "#0252CC",
                                                        borderRadius: "50%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        color: "#fff",
                                                        fontSize: "14px",
                                                        marginLeft: "10px"
                                                    }}
                                                >
                                                    {numberOfSelectedProductsInCategory(categoryId) || 0}
                                                </div>
                                            )}
                                        </Link>
                                        <div className={"catalog__submenu"}>
                                            <Link to="#" className="catalog__submenu-toggle"></Link>
                                        </div>
                                    </div>
                                    <div className={info.collapse[categoryId] ? "collapse show" : "collapse"}>
                                        {iikoCategoriesWithProducts[categoryId]?.map((productId, subIndex) => {
                                            if (iikoMenuSettings.iikoExternalMenuId ) {
                                                return (
                                                    <IikoItem
                                                        item={iikoExternalMenuItems[productId]}
                                                        onCheck={handleSelectProduct}
                                                        isChecked={selectedProducts.map((p) => (p as IikoExternalMenuItem).itemId).includes(productId)}
                                                        key={`${productId} - ${index} - ${subIndex}`}
                                                    />
                                                )
                                            }
                                            return (
                                                <IikoItem
                                                    item={iikoNomenclature?.products[productId]}
                                                    onCheck={handleSelectProduct}
                                                    isChecked={selectedProducts.map((p) => (p as IikoNomenclatureProduct).id).includes(productId)}
                                                    key={`${productId} - ${index} - ${subIndex}`}
                                                />
                                            )
                                        })}
                                    </div>
                                </li>
                            )
                        }))
                        :
                        (
                            <div className="alert alert-success py-4">
                                {i18n.help_empty_iiko_items()}
                            </div>
                        )
                    }
                </MenuUlWithLoader>
            </main>
        </section>
    )
}

interface IIikoItem {
    item: IikoNomenclatureProduct | IikoExternalMenuItem,
    isChecked: boolean,

    onCheck(product: IikoNomenclatureProduct | IikoExternalMenuItem): void,
}

function IikoItem(props: IIikoItem) {
    const {iikoMenuSettings} = useTypedSelector(state => state.iiko);
    return (
        <div
            className={"catalog__body"}
            onClick={() => {
                props.onCheck(props.item)
            }}
        >
            <div className="catalog__title">
                <Link to="#" className={`catalog__text`}>
                    <Checkbox
                        checked={props.isChecked}
                        label={""}
                        key={(iikoMenuSettings.iikoExternalMenuId ) ? (props.item as IikoExternalMenuItem).itemId : (props.item as IikoNomenclatureProduct).id}
                        style={{marginBottom: "inherit"}}
                    />
                    {(iikoMenuSettings.iikoExternalMenuId ) ? (props.item as IikoExternalMenuItem)?.name : (props.item as IikoNomenclatureProduct).data?.name}
                    <span className="catalog__arrow">{(iikoMenuSettings.iikoExternalMenuId ) ? (props.item as IikoExternalMenuItem).itemId : (props.item as IikoNomenclatureProduct).id}</span>
                    <span
                        className="catalog__arrow"
                        style={{
                            marginLeft: "auto",
                            marginRight: "20px"
                        }}
                    >
                        {
                            (iikoMenuSettings.iikoExternalMenuId )
                                ? (props.item as IikoExternalMenuItem)?.itemSizes?.find(size => size.sizeId === null)?.prices[0].price || 0
                                : (props.item as IikoNomenclatureProduct)?.data?.sizePrices?.find(size => size.sizeId === null)?.price?.currentPrice || 0
                        }
                    </span>
                </Link>
            </div>
        </div>
    )
}
