import React, {FC, useEffect, useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {isEmpty} from "lodash";
import {RouteNames} from "migration/pages";
import {KamiMenuItem} from "migration/entities/kamiMenuStruct";
import {RkeeperMenuClassification, RkeeperMenuProduct} from "migration/entities/rkeeperMenu";
import {IikoProductId, ItemId} from "migration/entities/baseAliases";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Button} from "migration/shared/ui/Button";
import {Notify} from "migration/shared/lib/notification/notification";
import {Loading} from "migration/shared/ui/Loading";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {CURRENCY} from "../../../../../utils/options/options";
import {Breadcrumbs} from "../../../../../components/breadcrumbs/breadcrumbs";
import {useGlobalContext, useUserContext} from "../../../../../hooks/globalContext";
import classes from "./RkeeperMenuBindings.module.scss";
import {Breadcrumb} from "antd";

export const RkeeperMenuBindings: FC = () => {
    const navigate = useNavigate();
    const {i18n} = useGlobalContext();
    const {user, menu, currentMenuId} = useUserContext();
    const {currentLang} = useTypedSelector(state => state.lang);
    const {
        rkeeperMenu,
        rkeeperMenuSettings,
        isLoadingSaveRkeeperProductBindings,
        isLoadingGetRkeeperMenu,
        isLoadingGetRkeeperMenuSettings
    } = useTypedSelector(state => state.rkeeper);
    const {menuData, isLoadingGetMenuData} = useTypedSelector(state => state.menuIiko);
    const {
        fetchMenuData,
        setIsLoadingGetMenuData,
        getMenuRkeeperSettings,
        getRkeeperMenu,
        saveRkeeperProductBindings
    } = useActions();

    const [selectedKamiProduct, setSelectedKamiProduct] = useState<ItemId>("");
    const [selectedRkeeperProduct, setSelectedRkeeperProduct] = useState<IikoProductId>("");
    const [selectedProductBinding, setSelectedProductBinding] = useState<ItemId>("");
    const [searchQueryKami, setSearchQueryKami] = useState<string>("");
    const [searchQueryBindings, setSearchQueryBindings] = useState<string>("");
    const [searchQueryRkeeper, setSearchQueryRkeeper] = useState<string>("");


    const kamiProducts: KamiMenuItem[] = useMemo(() => {
        const products: KamiMenuItem[] = [];

        menuData?.sections?.forEach((section) => {
            section?.categories?.forEach((category) => {
                category?.items?.forEach((item) => {
                    products.push(item);
                })
            })
        })

        const filteredProducts = products.filter(item => {
            return !Object.keys(rkeeperMenuSettings?.productBindings || {}).includes(item.id);
        });

        if (searchQueryKami) {
            return filteredProducts.filter((item) => {
                return item?.name?.[menu?.i18n?.defaultLang]?.toLowerCase().includes(searchQueryKami.toLowerCase());
            });
        }

        return filteredProducts || [];
    }, [menuData, menu, searchQueryKami, rkeeperMenuSettings]);

    const productBindings = useMemo(() => {
        const bindigns: { kamiProduct: KamiMenuItem, rkeeperProduct: RkeeperMenuProduct }[] = [];

        const kamiMenuProducts: KamiMenuItem[] = [];

        menuData?.sections?.forEach((section) => {
            section?.categories?.forEach((category) => {
                category?.items?.forEach((item) => {
                    kamiMenuProducts.push(item);
                })
            })
        })

        Object.keys(rkeeperMenuSettings?.productBindings || {}).forEach((kamiProductId) => {
            const rkeeperProductId = rkeeperMenuSettings?.productBindings[kamiProductId];
            const kamiProduct = kamiMenuProducts.find((item) => item.id === kamiProductId) || {id: kamiProductId} as KamiMenuItem;
            const rkeeperProduct = rkeeperMenu.products?.find(product => product.id === rkeeperProductId) || {id: rkeeperProductId} as RkeeperMenuProduct;
            if (!kamiProduct || !rkeeperProduct) {
                return;
            }
            bindigns.push({kamiProduct, rkeeperProduct});
        });

        if (searchQueryBindings) {
            return bindigns.filter((item) => {
                return item?.kamiProduct?.name?.[menu?.i18n?.defaultLang]?.toLowerCase().includes(searchQueryBindings.toLowerCase()) ||
                    item?.rkeeperProduct?.name?.toLowerCase().includes(searchQueryBindings.toLowerCase());
            });
        }

        return bindigns || [];
    }, [rkeeperMenu, rkeeperMenuSettings, menu, searchQueryBindings, menuData]);

    const uniqueRkeeperProducts: RkeeperMenuProduct[] = useMemo(() => {
        const rkeeperProducts: RkeeperMenuProduct[] = [];

        rkeeperMenu?.products?.map(product => product.id)?.filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
        }).forEach((rkeeperProductId) => {
            if (Object.values(rkeeperMenuSettings?.productBindings).includes(rkeeperProductId)) {
                return;
            }
            rkeeperProducts.push(rkeeperMenu.products?.find(product => product.id === rkeeperProductId) || {id: rkeeperProductId} as RkeeperMenuProduct);
        });

        const filteredRkeeperProducts = rkeeperProducts.filter(item => {
            return !Object.values(rkeeperMenuSettings?.productBindings || {}).includes(item.id);
        });

        if (searchQueryRkeeper) {
            return filteredRkeeperProducts.filter((item) => {
                return item?.name?.toLowerCase().includes(searchQueryRkeeper.toLowerCase());
            });
        }

        return filteredRkeeperProducts || [];
    }, [rkeeperMenuSettings, rkeeperMenu, searchQueryRkeeper]);

    const handleSelectKamiProduct = (kamiProductId: ItemId) => {
        if (selectedKamiProduct === kamiProductId) {
            setSelectedKamiProduct("");
            return;
        }
        setSelectedProductBinding("");
        setSelectedKamiProduct(kamiProductId);
    }

    const handleSelectProductBinding = (kamiProductId: ItemId) => {
        if (selectedProductBinding === kamiProductId) {
            setSelectedProductBinding("");
            return;
        }
        setSelectedRkeeperProduct("");
        setSelectedKamiProduct("");
        setSelectedProductBinding(kamiProductId);
    }

    const handleSelectRkeeperProduct = (rkeeperProductId: string) => {
        if (selectedRkeeperProduct === rkeeperProductId) {
            setSelectedRkeeperProduct("");
            return;
        }
        setSelectedProductBinding("");
        setSelectedRkeeperProduct(rkeeperProductId);
    }

    const bindProduct = async () => {
        if (!selectedKamiProduct || !selectedRkeeperProduct) {
            Notify.Info({title: txt.please_select_kami_and_iiko_product[currentLang], message: ""})
            return;
        }

        setIsLoadingGetMenuData(true);
        await saveRkeeperProductBindings(
            {
                "menuId": currentMenuId,
                "organizationId": user?.org?.id || "",
                "productBindings": {
                    ...rkeeperMenuSettings?.productBindings,
                    [selectedKamiProduct]: selectedRkeeperProduct
                }
            },
            {navigate: navigate},
        );
        setIsLoadingGetMenuData(false);
        setSelectedRkeeperProduct("");
        setSelectedKamiProduct("");
    }

    const unBindProduct = async (kamiProductId: ItemId) => {
        if (!kamiProductId) {
            Notify.Info({title: txt.please_select_product_binding[currentLang], message: ""})
            return;
        }

        const bindings = {...rkeeperMenuSettings?.productBindings};
        delete bindings[kamiProductId];
        setIsLoadingGetMenuData(true);
        await saveRkeeperProductBindings(
            {
                "menuId": currentMenuId,
                "organizationId": user?.org?.id || "",
                "productBindings": bindings
            },
            {navigate: navigate},
        );
        setIsLoadingGetMenuData(false);
        setSelectedProductBinding("");
    }

    const findClassificationsOfProduct = (rkeeperProductClassificationId: string) => {
        const classifications: RkeeperMenuClassification[] = [];
        const recursive: any = (classificationId: string) => {
            const classification = rkeeperMenu.classifications?.find(classification => classification.id === classificationId);
            if (!classification) {
                return null
            }
            classifications.push(classification)
            if (classification.parentId) {
                return recursive(classification.parentId)
            }
        }
        recursive(rkeeperProductClassificationId);
        return classifications.reverse();
    }

    useEffect(() => {
        const controller = new AbortController();
        getMenuRkeeperSettings({menuId: currentMenuId}, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMenuId]);

    useEffect(() => {
        if (isEmpty(rkeeperMenuSettings)) return;
        const controller = new AbortController();
        getRkeeperMenu({objectId: parseInt(rkeeperMenuSettings.rkeeperObjectId)}, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rkeeperMenuSettings]);

    useEffect(() => {
        const controller = new AbortController();
        fetchMenuData({menuId: currentMenuId}, controller, {navigate: navigate}, true);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <Breadcrumbs
                    infos={[
                        {title: i18n.menus(), link: RouteNames.MENUS, index: "bracer-0"},
                        {
                            title: i18n.rkeeper_product_bindings(),
                            link: RouteNames.MENUS_R_KEEPER_PRODUCT_BINDINGS,
                            index: "bracer-1"
                        }
                    ]}
                />

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.rkeeper_product_bindings()}</h1>
                    </div>

                    <div className={"app__header-right"} style={{display: "flex", gap: "20px"}}>
                        <Button
                            text={txt.unbind[currentLang]}
                            onClick={() => unBindProduct(selectedProductBinding)}
                            disabled={!selectedProductBinding}
                            isFilled={true}
                            isDelete={true}
                            // loading={isLoadingSaveIikoProductBindings}
                        />
                        <Button
                            text={txt.bind[currentLang]}
                            onClick={bindProduct}
                            disabled={!selectedKamiProduct || !selectedRkeeperProduct}
                            isFilled={true}
                            loading={isLoadingSaveRkeeperProductBindings}
                        />
                    </div>
                </div>

                <div className={classes.card}>
                    <div className={classes.table}>
                        <div className={classes.table__column}>
                            <div className={classes.table__column__header}>
                                {`${txt.kami_products[currentLang]} - ${kamiProducts?.length || 0}`}
                            </div>

                            <input
                                value={searchQueryKami}
                                onChange={(e) => setSearchQueryKami(e.target.value)}
                                placeholder={txt.search_kami_products_by_name[currentLang]}
                                className={classes.table__column__searchbar__input}
                            />

                            <div
                                className={classes.table__column__products}
                                style={{
                                    justifyContent: isLoadingGetMenuData ? "center" : "",
                                    height: isLoadingGetMenuData ? "100%" : ""
                                }}
                            >
                                {isLoadingGetMenuData
                                    ?
                                    <Loading/>
                                    :
                                    !!kamiProducts?.length
                                        ?
                                        kamiProducts.map(item => {
                                            return (
                                                <div
                                                    key={item?.id}
                                                    onClick={() => handleSelectKamiProduct(item?.id)}
                                                    className={classes.product}
                                                    style={{
                                                        backgroundColor: selectedKamiProduct === item?.id ? "#0252CC" : "",
                                                        color: selectedKamiProduct === item?.id ? "#fff" : ""
                                                    }}
                                                >
                                                    <div className={classes.product__title}>
                                                        {item?.name?.[menu?.i18n?.defaultLang]}
                                                    </div>
                                                    <div className={classes.product__price}>
                                                        {`${item?.prices?.primary?.price / 100} ${CURRENCY[menu.i18n.currency]}`}
                                                    </div>
                                                    <div className={classes.product__id}>
                                                        {item?.id}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className={classes.empty__list}>
                                            {txt.empty[currentLang]}
                                        </div>
                                }
                            </div>
                        </div>
                        <div className={classes.table__column}>
                            <div className={classes.table__column__header}>
                                {`${txt.bindings[currentLang]} - ${productBindings?.length || 0}`}
                            </div>

                            <input
                                value={searchQueryBindings}
                                onChange={(e) => setSearchQueryBindings(e.target.value)}
                                placeholder={txt.search_bindings_by_product_name[currentLang]}
                                className={classes.table__column__searchbar__input}
                            />

                            <div
                                className={classes.table__column__products}
                                style={{
                                    justifyContent: (isLoadingGetRkeeperMenuSettings || isLoadingGetRkeeperMenu) ? "center" : "",
                                    height: (isLoadingGetRkeeperMenuSettings || isLoadingGetRkeeperMenu) ? "100%" : ""
                                }}
                            >
                                {isLoadingGetRkeeperMenuSettings
                                    ?
                                    <Loading/>
                                    :
                                    !!productBindings.length
                                        ?
                                        productBindings.map((binding, index) => {
                                            return (
                                                <div
                                                    key={`${binding?.kamiProduct?.id} - ${binding.rkeeperProduct?.id} - ${index}`}
                                                    onClick={() => handleSelectProductBinding(binding?.kamiProduct?.id)}
                                                    className={classes.product__card}
                                                    style={{
                                                        marginBottom: "10px",
                                                        backgroundColor: selectedProductBinding === binding?.kamiProduct?.id ? "#0252CC" : "",
                                                        color: selectedProductBinding === binding?.kamiProduct?.id ? "#fff" : ""
                                                    }}
                                                >
                                                    <div
                                                        className={classes.product}
                                                        style={{borderBottom: "1px dashed #E5E5E5"}}
                                                    >
                                                        <div className={classes.product__title}>
                                                            {"KAMI"}
                                                        </div>
                                                        <div className={classes.product__title}>
                                                            {binding?.kamiProduct?.name?.[menu?.i18n?.defaultLang] || txt.product_not_found[currentLang]}
                                                        </div>
                                                        {!!binding?.kamiProduct?.prices?.primary?.price && (
                                                            <div className={classes.product__price}>
                                                                {`${binding?.kamiProduct?.prices?.primary?.price / 100} ${CURRENCY[menu.i18n.currency]}`}
                                                            </div>
                                                        )}
                                                        <div className={classes.product__id} style={{opacity: "1"}}>
                                                            {binding?.kamiProduct?.id}
                                                        </div>
                                                    </div>

                                                    {/*<React.Fragment>*/}
                                                    {/*    <div className={classes.product__card__title}>{"kami"}</div>*/}
                                                    {/*    <VerticalAlignMiddleOutlined*/}
                                                    {/*        className={classes.product__card__icon}/>*/}
                                                    {/*    <div className={classes.product__card__title}>{"iiko"}</div>*/}
                                                    {/*</React.Fragment>*/}

                                                    <div
                                                        className={classes.product}
                                                        style={{color: selectedProductBinding === binding?.kamiProduct?.id ? "#fff" : "#fc393d"}}
                                                    >
                                                        <div className={classes.product__title}>
                                                            {"R_KEEPER"}
                                                        </div>
                                                        <div className={classes.product__title}>
                                                            {binding?.rkeeperProduct?.name || txt.product_not_found[currentLang]}
                                                        </div>
                                                        {
                                                            binding?.rkeeperProduct.classificationIds?.map(classificationId => (
                                                                <Breadcrumb
                                                                    items={findClassificationsOfProduct(classificationId).map(classification => (
                                                                        {
                                                                            title: (
                                                                                <p
                                                                                    style={{
                                                                                        color: selectedProductBinding === binding?.kamiProduct?.id ? "#fff" : "",
                                                                                        marginBottom: "0"
                                                                                    }}
                                                                                >
                                                                                    {classification.name}
                                                                                </p>
                                                                            )
                                                                        }
                                                                    ))}
                                                                    style={{
                                                                        color: selectedProductBinding === binding?.kamiProduct?.id ? "#fff" : ""
                                                                    }}
                                                                />
                                                            ))
                                                        }
                                                        {(!!binding?.rkeeperProduct?.price || 0) && (
                                                            <div className={classes.product__price}>
                                                                {`${binding?.rkeeperProduct?.price} ${CURRENCY[menu.i18n.currency]}`}
                                                            </div>
                                                        )}
                                                        <div className={classes.product__id} style={{opacity: "1"}}>
                                                            {binding?.rkeeperProduct?.id}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className={classes.empty__list}>
                                            {txt.empty[currentLang]}
                                        </div>
                                }
                            </div>
                        </div>
                        <div className={classes.table__column}>
                            <div className={classes.table__column__header}>
                                {`${txt.rkeeper_product_bindings[currentLang]} - ${uniqueRkeeperProducts?.length || 0}`}
                            </div>

                            <input
                                value={searchQueryRkeeper}
                                onChange={(e) => setSearchQueryRkeeper(e.target.value)}
                                placeholder={txt.search_rkeeper_products_by_name[currentLang]}
                                className={classes.table__column__searchbar__input}
                            />

                            <div
                                className={classes.table__column__products}
                                style={{
                                    justifyContent: (isLoadingGetRkeeperMenuSettings || isLoadingGetRkeeperMenu) ? "center" : "",
                                    height: (isLoadingGetRkeeperMenuSettings || isLoadingGetRkeeperMenu) ? "100%" : ""
                                }}
                            >
                                {(isLoadingGetRkeeperMenuSettings || isLoadingGetRkeeperMenu)
                                    ?
                                    <Loading/>
                                    :
                                    !!uniqueRkeeperProducts.length
                                        ?
                                        uniqueRkeeperProducts.map((rkeeperProduct) => {
                                            return (
                                                <div
                                                    key={rkeeperProduct?.id}
                                                    onClick={() => handleSelectRkeeperProduct(rkeeperProduct?.id)}
                                                    className={classes.product}
                                                    style={{
                                                        backgroundColor: selectedRkeeperProduct === rkeeperProduct?.id ? "#0252CC" : "",
                                                        color: selectedRkeeperProduct === rkeeperProduct?.id ? "#fff" : ""
                                                    }}
                                                >
                                                    <div className={classes.product__title}>
                                                        {rkeeperProduct?.name}
                                                    </div>
                                                    {
                                                        rkeeperProduct.classificationIds?.map(classificationId => (
                                                            <Breadcrumb
                                                                items={findClassificationsOfProduct(classificationId).map(classification => (
                                                                    {
                                                                        title: (
                                                                            <p
                                                                                style={{
                                                                                    color: selectedRkeeperProduct === rkeeperProduct?.id ? "#fff" : "",
                                                                                    marginBottom: "0"
                                                                                }}
                                                                            >
                                                                                {classification.name}
                                                                            </p>
                                                                        )
                                                                    }
                                                                ))}
                                                                style={{
                                                                    color: selectedRkeeperProduct === rkeeperProduct?.id ? "#fff" : ""
                                                                }}
                                                            />
                                                        ))
                                                    }
                                                    <div className={classes.product__price}>
                                                        {`${rkeeperProduct?.price} ${CURRENCY[menu.i18n.currency]}`}
                                                    </div>
                                                    <div className={classes.product__id}>
                                                        {rkeeperProduct?.id}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className={classes.empty__list}>
                                            {txt.empty[currentLang]}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
