import { NotificationInstance } from "antd/es/notification/interface";
import { RootState, store } from "../../../app/store";

// type NotificationInsertion = 'top' | 'bottom';
// type NotificationContainer =
//     'bottom-left' |
//     'bottom-right' |
//     'bottom-center' |
//     'top-left' |
//     'top-right' |
//     'top-center' |
//     'center' |
//     'top-full' |
//     'bottom-full';

export interface Notification {
    title: string;
    message: string;
}

// const notificationConfig = {
//     insert: "top" as NotificationInsertion,
//     container: "top-center" as NotificationContainer,
//     animationIn: ["animate__animated", "animate__fadeIn"],
//     animationOut: ["animate__animated", "animate__fadeOut"],
//     dismiss: { duration: 5000, pauseOnHover: true, onScreen: true }
// }

export class Notify {
    private static getNotification(): NotificationInstance | null {
        const getNotificationApi = (state: RootState): NotificationInstance | null => state.system.notificationApi;
        const state = store.getState();
        return getNotificationApi(state);
    }

    public static Default = ({ title, message }: Notification) => {
        const notificationApi = Notify.getNotification();
        notificationApi && notificationApi.open({
            message: title,
            description: message,
        });
    };

    public static Info = ({ title, message }: Notification) => {
        const notificationApi = Notify.getNotification();
        notificationApi && notificationApi.info({
            message: title,
            description: message,
        });
    };

    public static Success = ({ title, message }: Notification) => {
        const notificationApi = Notify.getNotification();
        notificationApi && notificationApi.success({
            message: title,
            description: message,
        });
    };

    public static Warning = ({ title, message }: Notification) => {
        const notificationApi = Notify.getNotification();
        notificationApi && notificationApi.warning({
            message: title,
            description: message,
        });
    };

    public static Error = ({ title, message }: Notification) => {
        const notificationApi = Notify.getNotification();
        notificationApi && notificationApi.error({
            message: title,
            description: message,
        });
    };

    // public static Default = ({ title, message }: Notification) => {
    //     Store.addNotification({ ...notificationConfig, title: title, message: message, type: "default" });
    // };

    // public static Info = ({ title, message }: Notification) => {
    //     Store.addNotification({ ...notificationConfig, title: title, message: message, type: "info" });
    // };

    // public static Success = ({ title, message }: Notification) => {
    //     Store.addNotification({ ...notificationConfig, title: title, message: message, type: "success" });
    // };

    // public static Warning = ({ title, message }: Notification) => {
    //     Store.addNotification({ ...notificationConfig, title: title, message: message, type: "warning" });
    // };

    // public static Error = ({ title, message }: Notification) => {
    //     Store.addNotification({ ...notificationConfig, title: title, message: message, type: "danger" });
    // };
}
