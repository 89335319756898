import { SystemActionEnum, SetInitialLocationAction, SetNotificationAPIAction } from "./types";
import { NotificationInstance } from 'antd/es/notification/interface';

export const SystemActionCreators = {
    setInitialLocation: (payload: string): SetInitialLocationAction => ({
        type: SystemActionEnum.SET_INITIAL_LOCATION,
        payload
    }),
    setNotificationAPI: (api: NotificationInstance, contextHolder: React.ReactNode): SetNotificationAPIAction => ({
        type: SystemActionEnum.SET_NOTIFICATION_API,
        payload: { api, contextHolder }
    }),
}
