import { NotificationInstance } from 'antd/es/notification/interface';

export interface SystemState {
    initialLocation: string;
    notificationApi: NotificationInstance | null;
    notificationContextHolder: React.ReactNode | null;
}

export enum SystemActionEnum {
    SET_INITIAL_LOCATION = "SET_INITIAL_LOCATION",
    SET_NOTIFICATION_API = "SET_NOTIFICATION_API",
}

export type SetInitialLocationAction = {
    type: SystemActionEnum.SET_INITIAL_LOCATION;
    payload: string;
}

export type SetNotificationAPIAction = {
    type: SystemActionEnum.SET_NOTIFICATION_API;
    payload: {
        api: NotificationInstance;
        contextHolder: React.ReactNode;
    };
}

export type SystemAction = SetInitialLocationAction | SetNotificationAPIAction;
