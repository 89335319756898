import React, {FC, useEffect, useMemo, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {isEmpty} from "lodash";
import {RouteNames} from "migration/pages";
import {RkeeperMenuCategory, RkeeperMenuProduct} from "migration/entities/rkeeperMenu";
import {Notify} from "migration/shared/lib/notification/notification";
import {useActions} from "migration/shared/lib/hooks/useActions";
import {useTypedSelector} from "migration/shared/lib/hooks/useTypedSelector";
import {Checkbox} from "../../../../../components/input/checkbox";
import {Breadcrumbs} from "../../../../../components/breadcrumbs/breadcrumbs";
import {MenuUlWithLoader} from "../../../../../components/loader/menuUlWithLoader";
import {useGlobalContext, useUserContext} from "../../../../../hooks/globalContext";

export const MenuCopyRkeeper: FC = () => {
    const navigate = useNavigate();
    const {i18n, userInfoLoading} = useGlobalContext()
    const {currentMenuId, user} = useUserContext();

    const {
        rkeeperMenu,
        rkeeperMenuSettings,
        isLoadingGetRkeeperMenuSettings,
        isLoadingGetRkeeperMenu,
        isLoadingImportRkeeperProducts
    } = useTypedSelector(state => state.rkeeper);
    const {getMenuRkeeperSettings, getRkeeperMenu, importRkeeperProducts} = useActions();


    const [collapse, setCollapse] = useState<{ [key: string]: boolean }>({});

    const uniqueRkeeperProductCategories: { [rkeeperProductCategoryId: string]: RkeeperMenuCategory } = useMemo(() => {
        const rkeeperProductCategories: { [rkeeperProductCategoryId: string]: RkeeperMenuCategory } = {};

        rkeeperMenu?.categories?.map(category => category.name)?.filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
        })?.forEach((categoryName) => {
            const categoryId = rkeeperMenu?.categories?.find(category => category.name === categoryName)?.id || "";
            rkeeperProductCategories[categoryId] = rkeeperMenu?.categories?.find(category => category.id === categoryId) || {} as RkeeperMenuCategory;
        });

        // remove name duplicates
        const filteredRkeeperProductCategories: { [rkeeperProductCategoryId: string]: RkeeperMenuCategory } = {};
        Object.keys(rkeeperProductCategories).forEach((categoryId) => {
            filteredRkeeperProductCategories[categoryId] = rkeeperProductCategories[categoryId];
        });

        return rkeeperProductCategories;
    }, [rkeeperMenu]);

    const uniqueRkeeperProducts: { [rkeeperProductId: string]: RkeeperMenuProduct } = useMemo(() => {
        const rkeeperProducts: { [rkeeperProductId: string]: RkeeperMenuProduct } = {};

        rkeeperMenu?.products?.map(product => product.id)?.filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
        }).forEach((productId) => {
            if (Object.values(rkeeperMenuSettings?.productBindings).includes(productId)) {
                return;
            }
            rkeeperProducts[productId] = rkeeperMenu?.products?.find(product => product.id === productId) || {} as RkeeperMenuProduct;
        });

        return rkeeperProducts;
    }, [rkeeperMenu]);

    const rkeeperCategoriesWithProducts: { [categoryId: string]: string[] } = useMemo(() => {
        const rkeeperCategoriesWithProducts: { [categoryId: string]: string[] } = {};

        rkeeperCategoriesWithProducts[""] = [];
        Object.keys(uniqueRkeeperProductCategories || {}).forEach((categoryId) => {
            rkeeperCategoriesWithProducts[categoryId] = [];

            Object.keys(uniqueRkeeperProducts || {}).forEach((productId) => {
                const product = uniqueRkeeperProducts[productId];
                if (categoryId === product?.categoryId) {
                    rkeeperCategoriesWithProducts[categoryId].push(productId);
                }
            });
        });

        Object.keys(uniqueRkeeperProducts || {}).forEach((productId) => {
            const product = uniqueRkeeperProducts[productId];
            if (!product?.categoryId) {
                rkeeperCategoriesWithProducts[""].push(productId);
            }
        });

        const filteredRkeeperCategoriesWithProducts: { [categoryId: string]: string[] } = {};
        if (!isEmpty(uniqueRkeeperProducts)) {
            Object.keys(rkeeperCategoriesWithProducts).forEach((categoryId) => {
                if (rkeeperCategoriesWithProducts[categoryId].length > 0) {
                    filteredRkeeperCategoriesWithProducts[categoryId] = rkeeperCategoriesWithProducts[categoryId];
                }
            })
        }

        return filteredRkeeperCategoriesWithProducts || {};
    }, [uniqueRkeeperProductCategories, uniqueRkeeperProducts]);

    const [selectedProducts, setSelectedProducts] = useState<RkeeperMenuProduct[]>([]);

    const onCollapse = (id: string) => {
        setCollapse((prev) => ({...prev, [id]: !prev[id]}));
    }

    const numberOfSelectedProductsInCategory = (categoryId: string) => {
        return selectedProducts?.filter((p) => {
            if (!p.categoryId && categoryId === "") {
                return true;
            }
            return p.categoryId === categoryId
        })?.length || 0
    }

    const handleSelectProduct = (product: RkeeperMenuProduct) => {
        if (selectedProducts.map((p) => p.id).includes(product.id)) {
            setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
            return;
        }
        setSelectedProducts([...selectedProducts, product]);
    }

    const handleSelectAllProducts = () => {
        if (selectedProducts.length === Object.keys(uniqueRkeeperProducts).length) {
            setSelectedProducts([]);
            return;
        }

        const allProducts: RkeeperMenuProduct[] = [];
        Object.keys(rkeeperCategoriesWithProducts).forEach((categoryId) => {
            rkeeperCategoriesWithProducts[categoryId].forEach((productId) => {
                allProducts.push(uniqueRkeeperProducts[productId]);
            });
        });
        setSelectedProducts(allProducts);
    }

    const handleSelectUniqueProducts = () => {
        const productsNames: string[] = [];
        Object.keys(rkeeperCategoriesWithProducts).forEach((categoryId) => {
            rkeeperCategoriesWithProducts[categoryId].forEach((productId) => {
                productsNames.push(uniqueRkeeperProducts[productId]?.name);
            });
        });

        const uniqueProductsNames = productsNames.filter(function (item, pos) {
            return productsNames.indexOf(item) === pos;
        });

        // filter uniqueRkeeperProducts by name
        const uniqueProducts: RkeeperMenuProduct[] = [];
        uniqueProductsNames.forEach((productName) => {
            const product = Object.values(uniqueRkeeperProducts).find((p) => p.name === productName);
            if (product) {
                uniqueProducts.push(product);
            }
        });

        if (uniqueProducts.length === selectedProducts.length) {
            setSelectedProducts([]);
            return;
        }

        setSelectedProducts(uniqueProducts);
    }

    const copyProducts = () => {
        if (selectedProducts?.length === 0) {
            Notify.Info({title: i18n.please_select_products(), message: ""})
            return;
        }

        const rkeeperProducts: { [rkeeperProductCategoryName: string]: string[] } = {};
        Object.keys(rkeeperCategoriesWithProducts).forEach((categoryId) => {
            const category = rkeeperMenu?.categories.find((c) => c.id === categoryId);
            if (!category) return;
            rkeeperProducts[category.name] = [];
            rkeeperCategoriesWithProducts[categoryId].forEach((productId) => {
                if (selectedProducts.map((p) => p.id).includes(productId)) {
                    rkeeperProducts[category.name].push(productId);
                }
            });
        });

        const filteredRkeeperProducts: { [rkeeperProductCategoryId: string]: string[] } = {};
        Object.keys(rkeeperProducts).forEach((categoryName) => {
            const categoryId = rkeeperMenu?.categories.find(category => category.name === categoryName)?.id || "";
            if (rkeeperProducts[categoryName].length > 0) {
                filteredRkeeperProducts[categoryId] = rkeeperProducts[categoryName];
            }
        });

        importRkeeperProducts(
            {
                "menuId": currentMenuId,
                "organizationId": user?.org?.id || "",
                "rkeeperProducts": filteredRkeeperProducts
            },
            {navigate: navigate}
        );
        setSelectedProducts([]);
    }

    useEffect(() => {
        const controller = new AbortController();
        getMenuRkeeperSettings({menuId: currentMenuId}, controller);
        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMenuId]);

    useEffect(() => {
        if (isEmpty(rkeeperMenuSettings)) return;
        const controller = new AbortController();
        getRkeeperMenu({
            objectId: parseInt(rkeeperMenuSettings?.rkeeperObjectId)
        }, controller);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rkeeperMenuSettings]);

    useEffect(() => {
        document.title = i18n.menus()
    }, [i18n]);

    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">
                <Breadcrumbs
                    infos={[
                        {title: i18n.menus(), link: RouteNames.MENUS, index: "bracer-0"},
                        {title: i18n.copying_from_rkeeper(), link: RouteNames.MENUS_COPY_R_KEEPER, index: "bracer-1"}
                    ]}
                />

                <div className="app__header">
                    <div className="app__header-left">
                        <h1 className="app__header-title">{i18n.copying_from_rkeeper()}</h1>
                    </div>
                    <div className="app__header-right" style={{display: "flex"}}>
                        <Link to={RouteNames.MENUS} className="button -blue">
                            {i18n.cancel()}
                        </Link>
                        <button className="button -blue" onClick={handleSelectUniqueProducts}>
                            {i18n.check_unique()}
                        </button>
                        <button className="button -blue" onClick={handleSelectAllProducts}>
                            {i18n.check_all()}
                        </button>
                        <button className="button -blue" onClick={copyProducts}>
                            {i18n.copy_r_keeper()}
                        </button>
                    </div>
                </div>

                <div style={{width: "100%", display: "flex", alignItems: "center", marginBottom: "20px"}}>
                    <div style={{display: "flex", alignItems: "center", fontSize: "18px", fontWeight: "500"}}>
                        <div>{`${i18n.selected_products_amount()}: ${selectedProducts?.length || 0}`}</div>
                    </div>
                </div>

                <MenuUlWithLoader
                    id="catalog"
                    className="catalog"
                    loading={isLoadingGetRkeeperMenuSettings || isLoadingGetRkeeperMenu || isLoadingImportRkeeperProducts}
                    count={2}
                    level={2}
                    notFound={Object.keys(rkeeperCategoriesWithProducts).length === 0 && (isLoadingGetRkeeperMenu)}
                    notFoundText={i18n.help_empty_iiko_items()}
                >

                    {!!Object.keys(rkeeperCategoriesWithProducts || {}).length
                        ?
                        (Object.keys(rkeeperCategoriesWithProducts || {}).map((categoryId, index) => {
                            return (
                                <li className={"catalog__item"} key={categoryId}>
                                    <div className={"catalog__title"}>
                                        <Link
                                            to={"#"}
                                            className={collapse[categoryId] ? `catalog__text collapsed` : `catalog__text`}
                                            aria-expanded={collapse[categoryId] ? "true" : "false"}
                                            onClick={() => {
                                                onCollapse(categoryId)
                                            }}
                                        >
                                            {rkeeperMenu?.categories.find(category => category.id === categoryId)?.name || i18n.without_name()}
                                            <span className="catalog__subtext">
                                                ({rkeeperCategoriesWithProducts[categoryId]?.length || 0})
                                            </span>
                                            {numberOfSelectedProductsInCategory(categoryId) > 0 && (
                                                <div
                                                    style={{
                                                        width: "23px",
                                                        height: "23px",
                                                        backgroundColor: "#0252CC",
                                                        borderRadius: "50%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        color: "#fff",
                                                        fontSize: "14px",
                                                        marginLeft: "10px"
                                                    }}
                                                >
                                                    {numberOfSelectedProductsInCategory(categoryId) || 0}
                                                </div>
                                            )}
                                        </Link>
                                        <div className={"catalog__submenu"}>
                                            <Link to="#" className="catalog__submenu-toggle"></Link>
                                        </div>
                                    </div>
                                    <div className={collapse[categoryId] ? "collapse show" : "collapse"}>
                                        {rkeeperCategoriesWithProducts[categoryId]?.map((productId, subIndex) => {
                                            return (
                                                <RkeeperItem
                                                    item={rkeeperMenu?.products?.find((p) => p.id === productId) || {} as RkeeperMenuProduct}
                                                    onCheck={handleSelectProduct}
                                                    isChecked={selectedProducts.map((p) => p.id).includes(productId)}
                                                    key={`${productId} - ${index} - ${subIndex}`}
                                                />
                                            )
                                        })}
                                    </div>
                                </li>
                            )
                        }))
                        :
                        (
                            <div className="alert alert-success py-4">
                                {i18n.help_empty_iiko_items()}
                            </div>
                        )
                    }
                </MenuUlWithLoader>
            </main>
        </section>
    );
};

interface IRkeeperItem {
    item: RkeeperMenuProduct,
    isChecked: boolean,

    onCheck(product: RkeeperMenuProduct): void,
}

function RkeeperItem(props: IRkeeperItem) {
    return (
        <div
            className={"catalog__body"}
            onClick={() => {
                props.onCheck(props.item)
            }}
        >
            <div className="catalog__title">
                <Link to="#" className={`catalog__text`}>
                    <Checkbox
                        checked={props.isChecked}
                        label={""}
                        key={props.item.id}
                        style={{marginBottom: "inherit"}}
                    />
                    {props.item?.name}
                    <span className="catalog__arrow">{props.item.id}</span>
                    <span
                        className="catalog__arrow"
                        style={{
                            marginLeft: "auto",
                            marginRight: "20px"
                        }}
                    >
                        {props.item?.price || 0}
                    </span>
                </Link>
            </div>
        </div>
    )
}
